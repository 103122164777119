import {useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {DangerButton, PrimaryButton} from "../../elements/Buttons";
import {confirmTutoring, getTutoringWithSecret, rejectTutoring} from "../../../api/tutoring";
import {toast} from "react-toastify";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function TutoringConfirm() {
    const {id, secret} = useParams();
    const [tutoring, setTutoring] = useState()
    const {renderUserImage} = useUserInfo();

    useEffect(() => {
        loadData()
    }, [id, secret])

    async function loadData() {
        const newData = await getTutoringWithSecret(id, secret)
        setTutoring(newData)
    }

    async function handleConfirm() {
        try {
            const newData = await confirmTutoring(tutoring)
            setTutoring(newData)
            toast.success("Fördermaßnahme bestätigt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleReject() {
        try {
            const newData = await rejectTutoring(tutoring)
            setTutoring(newData)
            toast.success("Fördermaßnahme abgelehnt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    if (!tutoring) return <></>;

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(tutoring.student.user_id, 80)}

                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Nachhilfe {tutoring.subject.name} für {tutoring.student.full_name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {tutoring.student.student_number}, Klasse {tutoring.student.school_class}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Fach</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{tutoring.subject.name}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Fachlehrer</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{tutoring.teacher.full_name}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Nachhilfelehrer</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{tutoring.tutoring_teacher ? tutoring.tutoring_teacher.full_name : ""}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500"></dt>
                                            <dd className="mt-1 text-sm text-gray-900"></dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Beantragt von</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{tutoring.get_requested_by_display}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            {tutoring.status === 'REJECTED' && <>
                                                <dt className="text-sm font-medium text-gray-500">Abgelehnt von</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{tutoring.get_rejected_by_display}</dd>
                                            </>}
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                                            <dd className="mt-1 text-sm text-gray-900 flex">
                                                {tutoring.get_status_display}
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Förderplan</dt>
                                            <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap ">
                                                {tutoring.tutoring_plan}
                                            </dd>
                                        </div>

                                        {tutoring.status == 'PENDING' && <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Aktionen</dt>
                                            <dd className="mt-1 text-sm text-gray-900 flex gap-2">
                                                <PrimaryButton label="Bestätigen" onClick={handleConfirm}/>
                                                <DangerButton label="Ablehnen" onClick={handleReject}/>
                                            </dd>
                                        </div>}

                                    </dl>
                                </div>
                            </div>
                        </section>

                    </div>

                    <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
                    </section>
                </div>

            </main>
        </>
    );
}
