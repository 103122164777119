import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {deleteDownloads, getDownloads,} from "../../../api/downloads";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TwoLineColumnWithLink} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

const selectColumn = [
    {
        accessorKey: "id",
        id: "select",
        columnType: "select",
    },
];

const columns = [
    {
        accessorFn: (row) => `${row.title}`,
        id: "title_search",
        header: "search",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: "get_file_name",
        id: "get_file_name",
        header: "Datei",
        cell: ({row, value}) => (
            <TwoLineColumnWithLink
                link={`${row.original.file}`}
                line1={`${row.original.title}`}
                line2={`${row.original.get_file_name}`}
            />
        ),
        enableColumnFilter: false,
        disableRowLink: true,
        classNames: "max-w-sm overflow-hidden text-gray-500",
    },
    {
        id: "categories", // Used in m2m filter to get the array for the filter
        header: "Kategorien",
        accessorKey: "categories",
        labelAttr: "name", // Used in m2m filter to get the key to display from the dataset
        columnType: "m2m",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "keywords",
        id: "keywords",
        header: "Stichworte",
        enableColumnFilter: false,
        classNames: "max-w-md text-gray-500",
        headerClassNames: "max-w-md",
    },
    {
        id: "target_groups",
        accessorKey: "target_groups",
        header: "Zielgruppen",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        id: "created_by_full_name",
        accessorKey: "created_by_full_name",
        header: "Erstellt von",
        disableRowLink: true,
        enableColumnFilter: true,
        classNames: "text-gray-500",
    },
    {
        accessorKey: "last_update_date",
        id: "last_update_date",
        header: "Aktualisiert",
        columnType: "date",
        enableColumnFilter: true,
        filterFn: "isDateWithinRange",
        classNames: "text-gray-500",
    },
    {
        accessorKey: "expiration_date",
        id: "expiration_date",
        header: "Ablaufdatum",
        columnType: "date",
        enableColumnFilter: false,
        classNames: "text-gray-500",
    },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            row.original.can_edit ?
                <TableEditLink iconOnly={true} label="Bearbeiten" to={`/downloads/${row.original.id}/edit`}/> : <></>
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
        disableRowLink: true
    }
]

export default function DownloadList() {
    const {permissions} = useAuth();
    const [rowSelection, setRowSelection] = useState([]);
    const [category, setCategory] = useState(null);

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["downloads", category ? category.id : null],
        () => getDownloads(category ? category.id : null),
        {refetchInterval: 1000 * 60 * 60}
    )

    async function handleDelete() {
        deleteDownloads(rowSelection)
        toast.success('Downloads gelöscht')
        refetch()
    }

    // only show the select column if user has the right permissions
    let tableColumns = checkPermission(permissions, "downloads.delete_download")
        ? selectColumn.concat(columns)
        : columns;

    const button = checkPermission(permissions, 'downloads.add_download') ?
        <Link to="/downloads/new">
            <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
            >Neue Datei hochladen
            </button>
        </Link> : <></>

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div
                            className="scrollbarHeightListViewTable inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                            <div className="mt-4 sm:mt-0 sm:ml-16 float-right">
                                {button}
                            </div>

                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={tableColumns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    onDelete={handleDelete}
                                />
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
