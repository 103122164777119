import {Page} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import {TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    boardingSchoolAssessmentApi,
} from "../../../api/boarding_school";
import {PrimaryButton, SuccessButton} from "../../elements/Buttons";
import {classNames} from "../../../utils/misc";

export default function BoardingSchoolAssessmentEdit() {

    const {id} = useParams()
    const [data, setData] = useState()
    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await boardingSchoolAssessmentApi('get', id)
        setData(newData)
    }

    const schema = {};
    const title = "Internatsbeurteilung bearbeiten"
    const successMessage = "Internatsbeurteilung aktualisiert"
    const finalizeMessage = "Internatsbeurteilung abgeschlossen"

    async function onSave(finalized) {
        const payload = {...data, finalized}
        try {
            const newData = await boardingSchoolAssessmentApi('patch', null, payload)
            setData(newData)
            toast.success(finalized ? finalizeMessage : successMessage)
        } catch (e) {
            toast.error(...e.data)
        }
        // navigate(-1)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    if (!data) return <></>

    return (
        <Page title={title}>
            <div className="max-w-7xl mx-auto grid grid-cols-1 gap-6 grid-flow-col-dense">
                <div className="space-y-6 lg:col-span-4 w-full">
                    <div className="flex justify-between">
                        <p className="text-md font-medium">
                            {data.student.full_name} <br/>
                            {data.assessment_period.name}
                        </p>
                        <PrimaryButton
                            label="Schülerblatt"
                            link={`/school/students/${data.student.id}`}
                        />
                    </div>

                    {data.assessment_criterion.length > 0 &&
                    <div className="w-full align-middle">
                        <SchoolAssessmentTable
                            data={data}
                            setData={setData}
                        />
                    </div>}

                    <Form
                        id="boardingSchoolAssessment"
                        onSave={() => onSave(false)}
                        onDelete={null}
                        data={data} setData={setData}
                        errors={errors} setErrors={setErrors}
                        successMessage={successMessage}
                        schema={schema}
                    >
                        <FormSectionContent>
                            <TextArea
                                path="assessment"
                                label="Beurteilung"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                                rows={10}
                            />
                        </FormSectionContent>
                        {data.assessment?.length || 0} Zeichen
                    </Form>

                    <div className="mb-4">
                        <SuccessButton onClick={() => onSave(true)}
                                       classNameIn="cursor-pointer float-right font-medium"
                                       label="Speichern & abschließen"/>
                    </div>

                    <div className="mt-4">
                        <span onClick={() => navigate(-1)}
                              className="cursor-pointer mt-2 font-medium text-imsblue-600">Zurück</span>
                    </div>

                </div>
            </div>
        </Page>
    )
}

export function SchoolAssessmentTable({data, setData}) {

    const handleChange = (mark, rowId) => {
        const assessment_criterion = data.assessment_criterion.map(item => {
            if (item.criterion.id === rowId) {
                return {...item, mark}
            }
                return item
            }
        )
        setData({...data, assessment_criterion})
    }

    const getCurrentMark = (row) => {
        const currentCriterion = data.assessment_criterion.find(item => item.criterion.id === row.id)
        return currentCriterion.mark
    }

    return (
      <div className="w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5">
          <table className="w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                      <th className="text-center py-3.5 md:text-xs" colSpan={1}></th>
                      <th
                        className="text-center py-3.5 md:text-xs"
                        colSpan={data.assessment_criterion_mark_options.length}
                      >
                          Bewertung
                      </th>
                      <th className="text-center py-3.5 md:text-xs" colSpan={1}></th>
                  </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white">
              {data.assessment_criterion_name_options.map(row => {
                  const currentMark = getCurrentMark(row)
                  return (
                    <SchoolAssessmentTableRow
                      key={row.id}
                      row={row}
                      markOptions={data.assessment_criterion_mark_options}
                      currentMark={currentMark}
                      handleChange={handleChange}
                    />
                  )
              })}
              </tbody>
          </table>
      </div>
    )
}

function SchoolAssessmentTableRow({row, markOptions, currentMark, handleChange}) {
    return(
      <tr key={row.id} className="w-2 divide-x divide-y divide-gray-200">
          <td className="py-2 px-0.5 text-sm text-center">
              {row.text_ideal}
          </td>
          {markOptions.map(mark => (
            <SchoolAssessmentTableSlot
              key={mark.id}
              mark={mark}
              currentMark={currentMark}
              rowId={row.id}
              handleChange={handleChange}
            />
          ))}
          <td className="py-2 px-0.5 text-sm text-center">
              {row.text_worst}
          </td>
      </tr>
    )
}

function SchoolAssessmentTableSlot({mark, currentMark, rowId, handleChange}){
    return(
      <td className={classNames("min-w-[2rem] whitespace-nowrap py-2 text-center text-sm font-medium text-gray-900",
        currentMark?.id === mark.id && "bg-blue-200")}
        onClick={() => handleChange(mark, rowId)}
      >
          {mark.name}
      </td>
    )
}
