import React, {useEffect, useState} from "react";
import ListViewTable from "../../elements/tables/ListViewTable";
import {Link, useParams} from "react-router-dom";
import {getSchoolClass, getSchoolClassExcel, patchSchoolClass} from "../../../api/school";
import {DownloadButton, EditButton, PrimaryButton} from "../../elements/Buttons";
import {toast} from "react-toastify";
import {Select} from "../../elements/forms/Inputs";
import {baseHandleChange} from "../../elements/forms/Form";
import {useUserInfo} from "../../../contexts/UserInfoContext";
import env from "react-dotenv";

const columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row,
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    ...env.SCHOOL === 'KPI' ? [{
        accessorKey: "current_plupu_rating",
        id: "current_plupu_rating",
        header: "Status",
        columnType: "plupu_rating",
        enableColumnFilter: false
    }] : [],
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    }
];

export default function SchoolClassDetail() {

    const {id} = useParams();
    const {renderUserImage} = useUserInfo();
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([])
    const [editingMode, setEditingMode] = useState(false)

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        const newData = await getSchoolClass(id)
        setData(newData)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, {})
    }

    async function handleSave() {
        try {
            const newData = await patchSchoolClass(id, data)
            setData(newData)
            setEditingMode(false)
            toast.success("KlassensprecherIn gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    function getRowLink(row) {
        return `/school/students/${row.original.id}/`;
    }

    if (!data) return <></>;
    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{data.group}</h1>
                            {/*<p className="text-sm font-medium text-gray-500">{student.student_number}</p>*/}
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <DownloadButton
                            apiFunction={() => getSchoolClassExcel(id)}
                            fileName={`${data.group}.xls`}
                            label="Liste herunterladen"/>
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                        {/* Schüler */}
                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6">
                                    <h2
                                        id="notes-title"
                                        className="text-lg font-medium text-gray-900"
                                    >
                                        Schüler
                                    </h2>
                                </div>
                                <div className="mt-0">
                                    {data && (
                                        <ListViewTable
                                            data={data.students}
                                            columns={columns}
                                            disableGlobalSearch={true}
                                            getRowLink={getRowLink}
                                            noTopSpace={true}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-3 lg:col-span-1"
                    >
                        {data && (

                            <>
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                KlassenlehrerIn
                                            </h3>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                            <ul role="list" className="divide-y divide-gray-200">

                                                <Link to={`/staff/${data.class_teacher?.id}`}>
                                                    <li className="sm:px-6 flex py-4">
                                                        {renderUserImage(data.class_teacher.user_id)}
                                                        <div className="ml-3">
                                                            <p className="text-sm font-medium text-gray-900 py-2">
                                                                {data.class_teacher?.full_name}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </Link>

                                            </ul>
                                        </div>
                                    </div>
                                </section>

                                <section aria-labelledby="class-repr">
                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                KlassensprecherIn
                                            </h3>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">


                                            {!editingMode && <ul role="list" className="divide-y divide-gray-200">

                                                {data.class_representative &&
                                                    <Link to={`/school/students/${data.class_representative?.id}`}>
                                                        <li className="sm:px-6 flex py-4">
                                                            {renderUserImage(data.class_representative.user_id)}
                                                            <div className="ml-3">
                                                                <p className="text-sm font-medium text-gray-900 py-2">
                                                                    {data.class_representative?.full_name}
                                                                </p>
                                                            </div>
                                                        </li>
                                                    </Link>}

                                            </ul>}

                                            {editingMode && <div className="px-4 py-5 sm:px-6">

                                                <Select
                                                    path="class_representative"
                                                    label="KlassensprecherIn"
                                                    options={data.students}
                                                    valueAttr="id"
                                                    labelAttr="full_name"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={data}
                                                    isClearable={true}
                                                    valueOnly={false}
                                                />

                                                <PrimaryButton
                                                    classNameIn="my-4"
                                                    onClick={handleSave} label="Speichern"/>

                                            </div>}

                                        </div>
                                        {!editingMode && data.can_edit && <div className="px-4 py-5 sm:px-6">
                                            <EditButton
                                                iconOnly={false}
                                                onClick={() => setEditingMode(true)}
                                                label="KlassensprecherIn eintragen"
                                            />
                                        </div>}
                                    </div>
                                </section>
                            </>
                        )}
                    </section>
                </div>
            </main>
        </>
    );
}
