import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {Link} from "react-router-dom";
import {getStatistcs, startClassRegisterReminder} from "../../../api/class_register";
import {toast} from "react-toastify";
import {ClockIcon} from "@heroicons/react/20/solid";


export function RemindButton({label, apiFunction, classNameIn, ...rest}) {

    async function handleRemind() {
        try {
            const responseData = await apiFunction()
            toast.success("Erinnerung wird versendet.")
        } catch (e) {
            toast.error("Es ist ein Fehler aufgetreten")
        }
    }

    const button = <button onClick={handleRemind}
                           type="button"
                           className="cursor-pointer inline-flex items-center justify-center px-4 py-2
                           border border-gray-300 shadow-sm text-sm font-medium rounded-md
                           text-gray-700 bg-white hover:bg-gray-50 focus:outline-none
                           focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100
                           focus:ring-blue-500"
                           {...rest}
    >{label} <ClockIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
    </button>

    return button
}

const columns = [
    {
        accessorKey: "id",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course_number}
                to={`/courses/${row.original.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "school_subject.name",
        id: "school_subject",
        header: "Fach",
        enableColumnFilter: true,
        filterFn: "containsString",
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "teacher",
        id: "teacher",
        cell: ({row, value}) => (
            row.original.teacher ? <TableLink
                label={row.original.teacher.full_name}
                to={`/staff/${row.original.teacher.id}`}
            /> : <></>
        ),
        header: "Lehrer",
        disableRowLink: true,
        enableColumnFilter: true,
        filterFn: "containsString",
    },
    {
        accessorKey: "lessons_per_week",
        id: "lessons_per_week",
        header: "WoStd.",
        enableColumnFilter: false,
        classNames: 'max-w-sm'
    },
    {
        accessorKey: "ist_wostd",
        id: "ist_wostd",
        header: "IST WoStd.",
        enableColumnFilter: false,
        classNames: 'max-w-sm'
    },
    {
        accessorKey: "given_homework",
        id: "given_homework",
        header: "IST HA",
        enableColumnFilter: false,
        classNames: 'max-w-sm'
    },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            <div className="flex gap-2 items-center">
                <Link className="text-imsblue-600" to={`/class-register/courses/${row.original.id}`} >Kursbuch</Link>
                <Link className="text-imsblue-600" to={`/class-register/courses/${row.original.id}/homework`} >HA & Klausuren</Link>
                <RemindButton label="Erinnern" apiFunction={() => startClassRegisterReminder(row.original.id)} />
            </div>
        ),
        header: "",
        disableRowLink: true,
        enableColumnFilter: false,
    },
];

export default function Statistics() {

    const { permissions } = useAuth();

    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
        ["class_register_statistics"],
        getStatistcs,
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/class-register/courses/${row.original.id}/`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Übersicht Kursbucheinträge
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
