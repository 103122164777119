import {NarrowPage, Page} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {Input, MultiSelect, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {
    getLeadershipRoles, getStudentBookCodes,
    getStudentLeadershipRolesAll,
    getStudentLeadershipRoleStudentDetail, patchStudentBookCodes,
    patchStudentLeadershipRoles
} from "../../../api/school";
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {ExportPDFButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useUserInfo} from "../../../contexts/UserInfoContext";
import Joi from "joi";

export default function StudentBookCodesDetail() {

    const {studentId} = useParams()
    const {renderUserImage} = useUserInfo();

    const [data, setData] = useState({})
    const [errors, setErrors] = useState({}) // validation errors

    const schema = {
        cornelsen_id: Joi.string().email({tlds: {allow: false}}).allow('', null).label("Email"),
        klett_id: Joi.string().email({tlds: {allow: false}}).allow('', null).label("Email"),
        buchner_id: Joi.string().email({tlds: {allow: false}}).allow('', null).label("Email")
    }

    useEffect(() => {
        loadData()
    }, [studentId])

    async function loadData() {
        if (studentId) {
            const newData = await getStudentBookCodes(studentId)
            setData(newData)
        }
    }

    const title = (studentId || data.id) ? "Büchercodes bearbeiten" : "Büchercodes eintragen"
    const successMessage = "Gespeichert"

    async function onSave() {
        const newData = await patchStudentBookCodes(data.id, data)
        setData(newData)
        toast.success(successMessage)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    return (
        <main className={`py-10 h-full bg-gray-100`}>
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            {renderUserImage(data.user_id, 80)}
                            <span
                                className="absolute inset-0 shadow-inner rounded-full"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">
                            iPad Büchercodes für {data.full_name}
                        </h1>
                        <p className="text-sm font-medium text-gray-500">
                            {data.student_number}
                        </p>
                    </div>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    <SecondaryButton label="Schülerblatt" link={`/school/students/${data.id}`}/>
                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                <div className="space-y-6 col-span-2">

                    <Form id="studentBookCodes" onSave={onSave}
                          data={data} setData={setData}
                          errors={errors} setErrors={setErrors}
                          successMessage={successMessage}
                          schema={schema}>

                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex justify-between">
                                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                        Klasse {data.grade}
                                    </h2>
                                    <PrimaryButton onClick={onSave} label={"Speichern"}/>
                                </div>

                                <div className="px-4 py-6 grid grid-cols-6 gap-6 sm:px-6">

                                    <h3 className="text-lg leading-6 font-medium text-gray-900 sm:col-span-6 mt-4">Apple
                                        ID</h3>
                                    <div className="sm:col-span-3">
                                        <p className="text-sm">Apple ID</p>
                                        <p className="text-sm">{data.apple_id}</p>
                                    </div>
                                    <Input
                                        path="apple_password"
                                        type="text"
                                        label="Apple ID Passwort"
                                        className="sm:col-span-3"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />

                                    <h3 className="text-lg leading-6 font-medium text-gray-900 sm:col-span-6 mt-4">Microsoft
                                        ID</h3>
                                    <div className="sm:col-span-3">
                                        <p className="text-sm">Microsoft ID</p>
                                        <p className="text-sm">{data.microsoft_id}</p>
                                    </div>
                                    <Input
                                        path="microsoft_password"
                                        type="text"
                                        label="Microsoft ID Passwort"
                                        className="sm:col-span-3"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />


                                    <h3 className="text-lg leading-6 font-medium text-gray-900 sm:col-span-6 mt-4">Bibox</h3>
                                    <div className="sm:col-span-3">
                                        <p className="text-sm">Bibox ID</p>
                                        <p className="text-sm">{data.bibox_id}</p>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <p className="text-sm">Bibox Kennwort</p>
                                        <p className="text-sm">{data.bibox_passwort}</p>
                                    </div>


                                    <h3 className="text-lg leading-6 font-medium text-gray-900 sm:col-span-6 mt-4">Cornelsen</h3>
                                    <p className="text-sm sm:col-span-6">Hier musst du zunächst einen
                                        Schüler-Account bei <a href="https://Cornelsen.de"
                                                               className="text-imsblue-600 hover:text-imsblue-900 cursor-pointer"
                                                               target="_blank">cornelsen.de</a> anlegen. Dann kannst
                                        du die
                                        Codes in deinem Konto hinterlegen. </p>
                                    <Input
                                        path="cornelsen_id"
                                        type="text"
                                        label="Cornelsen Login"
                                        className="sm:col-span-3"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />
                                    <Input
                                        path="cornelsen_password"
                                        type="text"
                                        label="Cornelsen Kennwort"
                                        className="sm:col-span-3"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />

                                    <p className="text-sm sm:col-span-6"> Cornelsen Codes im Browser unter <a
                                        href="https://Cornelsen.de"
                                        className="text-imsblue-600 hover:text-imsblue-900 cursor-pointer"
                                        target="_blank">cornelsen.de</a> einlösen und dann in der App
                                        herunterladen.</p>

                                    <div className="sm:col-span-6">
                                        <p className="text-sm font-bold sm:col-span-6">Codes:</p>
                                        <ul className="text-sm  sm:col-span-6">
                                            <li>{data.cornelsen_code_1}</li>
                                            <li>{data.cornelsen_code_2}</li>
                                            <li>{data.cornelsen_code_3}</li>
                                            <li>{data.cornelsen_code_4}</li>
                                            <li>{data.cornelsen_code_5}</li>
                                            <li>{data.cornelsen_code_6}</li>
                                            <li>{data.cornelsen_code_7}</li>
                                        </ul>
                                    </div>


                                    <h3 className="text-lg leading-6 font-medium text-gray-900 sm:col-span-6 mt-4">Klett</h3>
                                    <p className="text-sm sm:col-span-6">Hier musst du zunächst einen
                                        Schüler-Account bei <a href="https://schueler.klett.de"
                                                               className="text-imsblue-600 hover:text-imsblue-900 cursor-pointer"
                                                               target="_blank">schueler.klett.de</a> anlegen. Dann
                                        kannst
                                        du die Codes in deinem Konto hinterlegen.</p>

                                    <Input
                                        path="klett_id"
                                        type="text"
                                        label="Klett Login"
                                        className="sm:col-span-3"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />
                                    <Input
                                        path="klett_password"
                                        type="text"
                                        label="Klett Kennwort"
                                        className="sm:col-span-3"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />

                                    <p className="text-sm sm:col-span-6">Klett Codes im Browser unter <a
                                        href="https://schueler.klett.de"
                                        className="text-imsblue-600 hover:text-imsblue-900 cursor-pointer"
                                        target="_blank">schueler.klett.de</a> einlösen und dann in der App
                                        herunterladen </p>

                                    <div className="sm:col-span-6">
                                        <p className="text-sm font-bold">Codes:</p>
                                        <ul className="text-sm">
                                            <li>{data.klett_code_1}</li>
                                            <li>{data.klett_code_2}</li>
                                            <li>{data.klett_code_3}</li>
                                            <li>{data.klett_code_4}</li>
                                            <li>{data.klett_code_5}</li>
                                            <li>{data.klett_code_6}</li>
                                        </ul>
                                    </div>


                                    <h3 className="text-lg leading-6 font-medium text-gray-900 sm:col-span-6 mt-4">Buchner
                                        Click & Study</h3>

                                    <p className="text-sm sm:col-span-6">Hier musst du zunächst einen
                                        Schüler-Account bei <a href="https://www.click-and-study.de"
                                                               className="text-imsblue-600 hover:text-imsblue-900 cursor-pointer"
                                                               target="_blank">www.click-and-study.de</a> anlegen.
                                        Dann
                                        kannst du die Codes in deinem Konto hinterlegen.</p>

                                    <Input
                                        path="buchner_id"
                                        type="text"
                                        label="Buchner Login"
                                        className="sm:col-span-3"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />
                                    <Input
                                        path="buchner_password"
                                        type="text"
                                        label="Buchner Kennwort"
                                        className="sm:col-span-3"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />

                                    <p className="text-sm sm:col-span-6">Klett Codes im Browser unter <a
                                        href="https://schueler.klett.de"
                                        className="text-imsblue-600 hover:text-imsblue-900 cursor-pointer"
                                        target="_blank">schueler.klett.de</a> einlösen und dann in der App
                                        herunterladen </p>

                                    <div className="sm:col-span-6">
                                        <p className="text-sm font-bold sm:col-span-6">Codes:</p>
                                        <ul className="text-sm">
                                            <li>{data.buchner_code_1}</li>
                                            <li>{data.buchner_code_2}</li>
                                            <li>{data.buchner_code_3}</li>
                                            <li>{data.buchner_code_4}</li>
                                            <li>{data.buchner_code_5}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>

                </div>


            </div>


        </main>

    )
}
