import {Link, useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getStudent, getStudentDocuments, studentDocumentApi} from "../../../api/school";
import {EditButton, PrimaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import moment from "moment";
import ListViewTable, {TwoLineColumnWithLink} from "../../elements/tables/ListViewTable";
import {formatDate} from "../../../utils/formatters";
import {DeleteButton} from "../../elements/DeleteButton";
import {toast} from "react-toastify";
import {useUserInfo} from "../../../contexts/UserInfoContext";

export default function StudentDetail() {
    const {studentId} = useParams();
    const {permissions} = useAuth();
    const {renderUserImage} = useUserInfo();

    const {data: student} = useQuery(["student", studentId], () =>
        getStudent(studentId)
    )

    if (!student) return <></>;

    const canViewStudentExtended = student.can_view_extended
    // const canViewStudentMini = checkPermission(permissions, "school.view_student_mini")
    const canViewStudentBasic = checkPermission(permissions, "school.view_student_basic")
    const canViewStudentComplete = checkPermission(permissions, "school.view_student")
    const canViewStudentBasicOrExtended = canViewStudentExtended || canViewStudentBasic

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(student.user_id, 120)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {student.full_name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        <PrimaryButton label="Notenblatt" link={`/grades/students/${studentId}/notenblatt`}/>
                        <PrimaryButton label="Logbuch" link={`/logbook/students/${studentId}`}/>

                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">


                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">

                                <div className="px-4 py-5 sm:px-6">
                                    <h2 id="applicant-information-title"
                                        className="text-lg font-medium leading-6 text-gray-900">
                                        Schülerdaten
                                    </h2>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                        {canViewStudentBasicOrExtended &&
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Status</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.get_status_display}
                                                    {student.in_transition && <>(Transit)</>}
                                                    {student.is_cooperation_student && <>(Kooperation)</>}
                                                </dd>
                                            </div>}


                                        {canViewStudentComplete && <>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Geburtsdatum</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{formatDate(student.birth_date)}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Geschlecht</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.get_gender_display}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Intern</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.boarding_school ? "Ja" : "Nein"}
                                                </dd>
                                            </div>
                                        </>}

                                        {canViewStudentBasicOrExtended && <>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Klassenstufe</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.grade}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Klasse</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <Link className="font-medium text-imsblue-600"
                                                          to={`/school/school-classes/${student.school_class?.id}`}>{student.school_class?.group}</Link>
                                                </dd>
                                            </div>
                                        </>}

                                        {(canViewStudentComplete || canViewStudentExtended) && <>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">KlassenlehrerIn</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <Link className="font-medium text-imsblue-600"
                                                          to={`/staff/${student.school_class?.class_teacher?.id}`}>{student.school_class?.class_teacher?.full_name}</Link>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Klassensprecher</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {student.school_class?.class_representative?.id === studentId ? "Ja" : "Nein"}
                                                </dd>
                                            </div>
                                        </>}

                                        {canViewStudentBasicOrExtended && <>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Mentorat</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <Link className="font-medium text-imsblue-600"
                                                          to={`/boarding-school/houses/${student.house_id}`}>{student.house}</Link>
                                                </dd>
                                            </div>
                                        </>}

                                        {(canViewStudentComplete || canViewStudentExtended) && <>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Zimmer</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.room}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">StammentorIn</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {student.main_mentor &&
                                                        <Link className="font-medium text-imsblue-600"
                                                              to={`/staff/${student.main_mentor.id}`}>{student.main_mentor.full_name}</Link>}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Team</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {student.team && <Link className="font-medium text-imsblue-600"
                                                                           to={`/boarding-school/teams/${student.team.id}`}>{student.team.name}</Link>}
                                                </dd>
                                            </div>
                                        </>}

                                        {canViewStudentComplete && <>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Bemerkungen</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.notes}</dd>
                                            </div>
                                        </>}

                                    </dl>
                                </div>
                            </div>
                        </section>

                        {canViewStudentComplete &&
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Eltern
                                        </h2>
                                    </div>

                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                                        {student.parent_relationships.map((parentRelationship, index) => (

                                            <dl key={index}
                                                className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 " + (index > 0 ? "mt-8" : "")}>
                                                <div className="sm:col-span-2">
                                                    <dt className="text-md font-medium text-gray-500">{parentRelationship.parent?.user?.full_name}</dt>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Elternrolle</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{parentRelationship.get_parent_role_display}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Erziehungsberechtigt</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{parentRelationship.has_parental_authority ? "Ja" : "Nein"}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {parentRelationship.parent.street} <br/>
                                                        {parentRelationship.parent.zip_code} {parentRelationship.parent.city}
                                                        <br/>
                                                        {parentRelationship.parent.country}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.user?.email}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Telefon privat
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.phone_private}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Telefon
                                                        geschäftlich
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.phone_work}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Telefon mobil</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.mobile}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Fax</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.fax}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Beruf</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.profession}</dd>
                                                </div>
                                            </dl>
                                        ))}

                                    </div>

                                </div>
                            </section>}

                        {canViewStudentComplete &&
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Status
                                        </h2>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Geburtsort</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.place_of_birth}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Staatsangehörigkeit</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.nationality}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Eintritt</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{formatDate(student.entry_date)}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Kündigung zum</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{formatDate(student.termination_date)}</dd>
                                            </div>

                                            {student.status === 'ALUMNUS' && <>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Austritt</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{formatDate(student.exit_date)}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Austrittsgrund</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{student.reason_for_termination}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Abschluss</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{student.graduation}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Notenschnitt</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{student.grade_point_average}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Folgeschule</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{student.next_school}</dd>
                                                </div>
                                            </>}

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Quelle</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.source}</dd>
                                            </div>

                                        </dl>
                                    </div>
                                </div>
                            </section>}

                        {canViewStudentComplete &&
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Schule
                                        </h2>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Schulart</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.school_type}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Studierzimmer</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.study_hall}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Sternchenschüler</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.is_sternchen ? "Ja" : "Nein"}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">1. Fremdsprache</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.foreign_language_1}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">2. Fremdsprache</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.foreign_language_2}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">3. Fremdsprache</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.foreign_language_3}</dd>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Schulische
                                                    Besonderheiten
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.school_particularities}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Hochbegabt</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.gifted}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">ADS</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.ads}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">ADHS</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.adhs}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">LRS</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.lrs}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Religion</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.religion}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Wiederholte Klassen
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.repeated_grades}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Übersprungen</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.skipped_grades}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Versetzungsstatus</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.promotion_status}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Letzte Schule</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.previous_school}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Nachteilsausgleich</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.disadvantage_compensation}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>}

                        {canViewStudentComplete &&
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Internat
                                        </h2>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Dauerabmeldung</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.permanent_absence ? "Ja" : "Nein"}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Transportziel</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.travel_destination}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Flughafen</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.airport}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">PKW-Erlaubnis</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.pkw_permission}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Mitfahrerlaubnis
                                                    Eltern
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.car_ride_permission_parents ? "Ja" : "Nein"}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Mitfahrerlaubnis
                                                    Schüler
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.car_ride_permission_students ? "Ja" : "Nein"}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Badeerlaubnis</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.swimming_permission}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Nichtraucher</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.non_smoker ? "Ja" : "Nein"}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Vegetarier</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.vegetarian ? "Ja" : "Nein"}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>}


                        {canViewStudentComplete &&
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Gesundheit
                                        </h2>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Medikamente</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.medications}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Krankheit</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.illness}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Krankenkasse</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.health_insurance}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Impfungen</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.vaccinations}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Tetanus</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.tetanus}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Masernimpfung</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.measles_vaccine}</dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">COVID-19 Impfung</dt>
                                                <dd className="mt-1 text-sm text-gray-900">

                                                    {student.covid_vaccine && <>
                                                        {student.covid_vaccine <= new Date(Date.now() - 1000 * 60 * 60 * 24 * 14)
                                                            ? "Voller Impfschutz"
                                                            : `Voller Impfschutz ab ${formatDate(new Date(Date.parse(student.covid_vaccine) + 1000 * 60 * 60 * 24 * 14))}`}
                                                    </>}

                                                    {!student.covid_vaccine && <>
                                                        Kein Impfschutz
                                                    </>}

                                                    {student.covid_booster_vaccine && <>
                                                        <br/> Booster-Impfung
                                                        am {formatDate(student.covid_booster_vaccine)}
                                                    </>}

                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">COVID-19 Erkrankung
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {student.covid_infection &&
                                                        <>Erkrankung am {formatDate(student.covid_infection)}</>
                                                    }
                                                    {!student.covid_infection &&
                                                        <>Keine Erkrankung</>
                                                    }
                                                </dd>
                                            </div>

                                        </dl>
                                    </div>
                                </div>
                            </section>}

                        {/* Student documents */}
                        {canViewStudentComplete && <section aria-labelledby="notes-title">
                            <StudentDocuments
                                studentId={studentId}
                                permissions={permissions}
                            />
                        </section>}

                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-3 lg:col-span-1"
                    >
                        {canViewStudentBasicOrExtended &&
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Kontakt
                                        </h3>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                        <dl className="sm:divide-y sm:divide-gray-200">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {student.email &&
                                                        <a href={`mailto:${student.email}`}>
                                                            {student.email}</a>}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Handy</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {student.mobile_number}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Letzter Login</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {formatDate(student.last_login)}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>}

                        <section aria-labelledby="student-links">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-5">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Links
                                    </h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                    <dl className="sm:divide-y sm:divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <Link className="font-medium text-imsblue-600"
                                                  to={`/assessments/students/${studentId}`}>Beurteilungen
                                            </Link>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <Link className="font-medium text-imsblue-600"
                                                  to={`/courses/students/${studentId}`}>Kurse</Link>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <Link className="font-medium text-imsblue-600"
                                                  to={`/class-register/students/${studentId}`}>Hausaufgaben</Link>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <Link className="font-medium text-imsblue-600"
                                                  to={`/projects/students/${studentId}`}>Projekte</Link>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <Link className="font-medium text-imsblue-600"
                                                  to={`/logbook/absence-notices/students/${studentId}`}>Abwesenheit</Link>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <Link className="font-medium text-imsblue-600"
                                                  to={`/school/book-codes/${studentId}`}>Büchercodes</Link>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>

            </main>
        </>
    );
}


function StudentDocuments({studentId, permissions}) {

    const [data, setData] = useState()

    useEffect(() => {
        loadData()
    }, [studentId])

    async function loadData() {
        if (!studentId) return
        const newData = await getStudentDocuments(studentId)
        setData(newData)
    }

    async function handleDelete(id) {
        try {
            await studentDocumentApi("delete", id)
            loadData()
            toast.success("Dokument gelöscht")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    const columns = [
        {
            accessorKey: "get_file_name",
            id: "get_file_name",
            header: "Datei",
            cell: ({row, value}) => (
                <TwoLineColumnWithLink
                    link={`${row.original.file}`}
                    line1={`${row.original.title}`}
                    line2={`${row.original.get_file_name}`}
                />
            ),
            disableRowLink: true,
            enableColumnFilter: false,
            classNames: "max-w-md text-gray-500 break-all",
            headerClassNames: "max-w-md",
        },
        {
            accessorKey: "title",
            id: "title",
            header: "Titel",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "description",
            id: "description",
            header: "Beschreibung",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            id: "creation_date",
            header: "Hochgeladen von",
            cell: ({row, value}) => (
                <TwoLineColumnWithLink
                    line1={`${row.original.created_by_full_name}`}
                    line2={"am " + moment(value).format("DD.MM.YYYY")}
                />
            ),
            enableColumnFilter: false,
            disableRowLink: true,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <>
                    <DeleteButton onDelete={() => handleDelete(info.getValue())}
                                  iconOnly={true}
                    />
                    <EditButton iconOnly={true} link={`/school/student-documents/${info.getValue()}/edit`}/>
                </>
            ),
            header: "",
            enableColumnFilter: false,
        },
    ];

    return (
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Dokumente
                    </h2>
                    <PrimaryButton link={`/school/student-documents/new/${studentId}`} label="Dokument einstellen"/>
                </div>
                <div className="">
                    {data && (
                        <ListViewTable
                            data={data}
                            columns={columns}
                            disableGlobalSearch={true}
                            getRowLink={null}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
