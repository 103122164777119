import Joi from "joi";
import {React, useState} from "react";
import {Page} from "../../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {getMyStudents, getSchoolSubjects} from "../../../api/school";
import Form, {baseHandleChange,} from "../../elements/forms/Form";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {Select,} from "../../elements/forms/Inputs";
import {getTutoringDates, getTutoringTeacher, requestTutoring} from "../../../api/tutoring";
import {getTeacherList} from "../../../api/staff";
import {validateProperty} from "../../../utils/validation";
import {useAuth} from "../../../contexts/AuthContext";

export default function TutoringRequest() {

    const newTutoring = {
        id: null,
        student: null,
        subject: null,
        teacher: null,
        status: "OPEN",
        requested_by: "PARENTS",
        start_date: null
    }

    const [data, setData] = useState({...newTutoring});

    const {currentUser} = useAuth()
    const {data: students} = useQuery(['my_students', currentUser.user_id], getMyStudents, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: schoolSubjects} = useQuery(["school_subjects"], getSchoolSubjects, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: teachers} = useQuery(["teachers"], getTeacherList, {refetchInterval: 1000 * 60 * 60 * 24})

    const {data: tutoringDates} = useQuery(
        ["tutoring_dates"],
        () => getTutoringDates(),
        {}
    )

    const [errors, setErrors] = useState([]); // validation errors

    let navigate = useNavigate()

    const schema = {
        student: Joi.object().required(),
        subject: Joi.object().required(),
        teacher: Joi.object().required()
    };

    const title = "Fördermaßnahme beantragen"
    const successMessage = "Fördermaßnahme beantragt."

    async function onSave() {
        const newTutoring = await requestTutoring(data)
        toast.success(successMessage);
        return navigate(`/tutoring/${newTutoring.id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleSubjectOrStudentChange(evt) {

        const value = evt.target.value
        const student = evt.target.name === 'student' ? value : data.student
        const subject = evt.target.name === 'subject' ? value : data.subject
        let subjectTeacher = data.teacher
        let newData

        // look up the course teacher for this subject and student
        if (student && subject) {
            getTutoringTeacher(student.id, subject.id).then((subjectTeacher) => {
                if (subjectTeacher.teacher) {
                    subjectTeacher = subjectTeacher.teacher
                }
                newData = {...data, [evt.target.name]: value, teacher: subjectTeacher}
                setData(newData)
            })
        } else {
            newData = {...data, [evt.target.name]: value, teacher: subjectTeacher}
            setData(newData)
        }

        // field validation
        const newErrors = {...errors, [evt.target.name]: validateProperty(evt.target, schema, data)}
        setErrors(newErrors)
    }

    return (
        <>
            <Page title={title}>
                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">

                    <div className="space-y-6 lg:col-span-6">
                        <Form
                            id="requestTutorings"
                            onSave={onSave}
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            successMessage={successMessage}
                            schema={schema}
                            submitButtonLabel="Fördermaßnahme beantragen"
                        >

                            <FormSectionContent>

                                <div className="py-2 pt-4 space-y-6 lg:col-span-3">
                                    <Select
                                        path="student"
                                        label="SchülerIn"
                                        options={students}
                                        valueAttr="id"
                                        labelAttr="full_name"
                                        onChange={handleSubjectOrStudentChange}
                                        errors={errors}
                                        data={data}
                                        isClearable={false}
                                        valueOnly={false}
                                    />

                                    <Select
                                        path="subject"
                                        label="Fach"
                                        options={schoolSubjects}
                                        valueAttr="id"
                                        labelAttr="name"
                                        onChange={handleSubjectOrStudentChange}
                                        errors={errors}
                                        data={data}
                                        isClearable={false}
                                        valueOnly={false}
                                    />

                                    <Select
                                        path="teacher"
                                        label="FachlehrerIn"
                                        options={teachers}
                                        valueAttr="id"
                                        labelAttr="full_name"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                        isClearable={false}
                                        valueOnly={false}
                                        disabled={true}
                                    />

                                    <Select
                                        path="start_date"
                                        label="Start zum"
                                        className="sm:col-span-3"
                                        options={tutoringDates}
                                        valueAttr="date"
                                        labelAttr="date_display"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                        isClearable={false}
                                        valueOnly={true}
                                    />


                                </div>


                            </FormSectionContent>


                        </Form>

                        <span onClick={() => navigate(-1)}
                              className="cursor-pointer float-right mt-2 font-medium text-imsblue-600"
                        > Zurück</span>
                    </div>

                </div>
            </Page>
        </>
    );
}
