import {useParams} from "react-router-dom";
import {React} from "react";
import {useQuery} from "@tanstack/react-query";
import {EditButton, ExportPDFButton, PrimaryButton} from "../../elements/Buttons";
import ListViewTable from "../../elements/tables/ListViewTable";
import {DetailPage} from "../../elements/Pages";
import {formatDate} from "../../../utils/formatters";
import {resultApi} from "../../../api/grades";


const columns = [
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name}`,
        id: "student",
        header: "SchülerIn",
        enableColumnFilter: false,
    },
    {
        accessorKey: "mark.display",
        id: "mark",
        header: "Note",
        enableColumnFilter: false
    }
]

export default function ResultDetail() {

    const {id} = useParams();

    const {data: result} = useQuery(
        ["result_detail", id],
        () => resultApi('get', id)
    )

    if (!result) return <></>;

    const exportPDFData = <ResultDetailContent result={result} exporting={true}/>

    return (
        <DetailPage>
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <h1 className="text-2xl font-bold text-gray-900">
                        Ergebnis für {result.mark_definition.course.course_number} {result.mark_definition.course.school_subject?.name}
                    </h1>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    <ExportPDFButton
                        data={exportPDFData}
                        portrait={true}
                        fileName={`${result.mark_definition.title} ${result.date} ${result.mark_definition.course.course_number}.pdf`}
                        label="PDF herunterladen"
                    />
                    <EditButton
                        link={`/grades/results/${id}/edit`}
                        label="Bearbeiten"
                    />
                    <PrimaryButton
                        link={`/grades/courses/${result.mark_definition.course.id}`}
                        label="Notendefinitionen"
                    />
                </div>
            </div>

            <ResultDetailContent result={result} exporting={false}/>

        </DetailPage>


    );
}


function ResultDetailContent({result, exporting}) {

    return <>

        {exporting && <div className="mt-4 sm:px-10">
            <h1 className="text-md font-bold">
                Ergebnis
                für {result.mark_definition.course.course_number} {result.mark_definition.course.school_subject?.name}</h1>
        </div>}

        <div
            className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

            <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                <section aria-labelledby="applicant-information-title">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">

                            <dl className="sm:divide-y sm:divide-gray-200">

                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Datum</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatDate(result.date)}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Thema</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{result.topic}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Eingetragen von</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {result.created_by_full_name}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Durchschnitt</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {result.get_average}
                                    </dd>
                                </div>
                                {result.attachment &&
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Anhang</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <a href={result.attachment} target="_blank">
                                                {result.get_file_name}
                                            </a>
                                        </dd>
                                    </div>}
                            </dl>
                        </div>
                    </div>
                </section>

            </div>

        </div>

        <div
            className="mt-0 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-b-6 lg:col-start-1 lg:col-span-2">
                {result.result_lines && (
                    <ListViewTable
                        data={result.result_lines}
                        columns={columns}
                        disableGlobalSearch={true}
                        getRowLink={null}
                    />
                )}

            </div>
        </div>
    </>
}
