import Joi from "joi";
import {React, useEffect, useState} from "react";
import {Page} from "../../elements/Pages";

import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getRecurringRules} from "../../../api/schedule";
import {formatDateTime} from "../../../utils/formatters";
import Form, {baseHandleChange, baseHandleDateChange,} from "../../elements/forms/Form";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {DateTimeInput, Input, Select,} from "../../elements/forms/Inputs";
import {
    cancelDeviceBookingOccurrence,
    deviceBookingApi,
    getDevices,
    uncancelDeviceBookingOccurrence
} from "../../../api/organizational";
import {AvailableDevices, FindDevice} from "./FindDevice";

export default function DeviceBookingCreateEdit() {
    const {id, deviceId} = useParams()
    const queryClient = useQueryClient()

    const [data, setData] = useState({
        id: null,
        device: {id: parseInt(deviceId) || null},
        rule: null,
        start: null,
        end: null,
        end_recurring_period: null,
        number_of_items: null
    });

    const {data: rules} = useQuery(["recurring_rules"], getRecurringRules, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: devices} = useQuery(["devices"], getDevices, {refetchInterval: 1000 * 60 * 60 * 24});

    const [availableDevices, setAvailableDevices] = useState([])
    const [errors, setErrors] = useState({}); // validation errors
    const [bookingValidationErrors, setBookingValidationErrors] = useState([])

    let navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, [id]);

    async function loadData() {
        // load existing booking if editing
        if (!id) return;
        const newData = await deviceBookingApi("get", id);
        setData(newData);
    }

    const schema = {
        device: Joi.any().required(),
        start: Joi.date().required(),
        end: Joi.date().required().min(
            Joi.ref("start", {
                adjust: (value) => (value ? value.setSeconds(1) : new Date(1970, 1, 1)),
            })
        ).messages({"date.min": "Ende muss nach dem Start liegen"}),
    }

    const title =
        id || data.id
            ? "Gerätebuchung bearbeiten"
            : "Gerät buchen";
    const successMessage =
        id || data.id
            ? "Gerätebuchung aktualisiert"
            : "Gerät gebucht";

    async function onSave() {
        let newData;
        try {
            if (!data.id) {
                newData = await deviceBookingApi("post", null, data);
            } else {
                newData = await deviceBookingApi("patch", null, data);
                // setData(newData);
            }
            setData(newData);
            setBookingValidationErrors([])
            queryClient.invalidateQueries({ queryKey: ['device_bookings'] })
            toast.success(successMessage);
            navigate(`/service/device-bookings/${data.device.id}/calendar`)
        } catch (e) {
            setBookingValidationErrors(e.data)
            throw(e)
        }
    }

    async function onDelete() {
        try {
            await deviceBookingApi("delete", data.id);
            toast.success("Gerätebuchung gelöscht.");
            navigate(`/service/device-bookings/${data.device.id}/calendar`)
        }
        catch (e) {
            if (e.status === 403) {
                toast.error("Du kannst diese Buchung nicht löschen, da sie nicht von dir erstellt wurde.")
            } else {
                toast.error("Fehler beim Löschen der Gerätebuchung.")
            }
        }
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema);
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(
            date,
            path,
            data,
            setData,
            errors,
            setErrors,
            schema,
            true
        );
    }

    function handleSelectDevice(device) {
        setData({
            ...data, device: device
        })
    }
    
    function handelCancelOccurrence(occ) {
        cancelDeviceBookingOccurrence(data.id, occ);
        loadData();
    }

    function handelUncancelOccurrence(occ) {
        uncancelDeviceBookingOccurrence(data.id, occ);
        loadData();
    }

    return (
        <>
            <Page title={title}>
                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                    <div className="space-y-6 lg:col-span-4">
                        <Form
                            id="createDeviceBooking"
                            onSave={onSave}
                            onDelete={onDelete}
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            successMessage={successMessage}
                            schema={schema}
                        >
                            <FormSectionContent>

                                <DateTimeInput
                                    className="col-span-3"
                                    path="start"
                                    label="Von"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={true}
                                    tomorrowButton={true}
                                    isDayPeriods={true}
                                    typeDayPeriod="start_time"
                                />

                                <DateTimeInput
                                    className="col-span-3"
                                    path="end"
                                    label="Bis"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={true}
                                    tomorrowButton={true}
                                    minDate={new Date(data.start) || null}
                                    isDayPeriods={true}
                                    typeDayPeriod="end_time"
                                />

                                <Select
                                    path="rule"
                                    label="Wiederholung"
                                    className="sm:col-span-3"
                                    options={rules}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />

                                <DateTimeInput
                                    className="col-span-3"
                                    path="end_recurring_period"
                                    label="Ende Wiederholung"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={false}
                                    tomorrowButton={false}
                                    minDate={new Date(data.start) || null}
                                />

                                <Select
                                    path="device"
                                    label="Gerät"
                                    className="sm:col-span-5"
                                    options={devices}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />

                                <div className="flex justify-start items-end sm:col-span-1">
                                    <FindDevice data={data} setAvailableDevices={setAvailableDevices} errors={errors}
                                                setErrors={setErrors}/>
                                </div>

                                <Input
                                    path="number_of_items"
                                    type="number"
                                    label="Anzahl"
                                    helpText="Leerlassen, um den gesamten Gerätesatz zu buchen"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                {bookingValidationErrors && <div className="px-2 bg-red-50 text-red-700 sm:col-span-6">
                                    <ul role="list" className="space-y-1">
                                        {bookingValidationErrors.map((error, index) => (
                                            <li key={index} className="flex py-2">{error.error}: &nbsp;
                                                <Link className="font-medium underline"
                                                      to={`/service/device-bookings/calendar/?event_id=${error.device_booking.id}`}>{error.device_booking?.id}</Link>
                                            </li>
                                        ))}
                                    </ul>

                                </div>}

                            </FormSectionContent>

                        </Form>

                        <span
                            onClick={() => navigate(-1)}
                            className="cursor-pointer float-right mt-2 font-medium text-imsblue-600"
                        >
              Zurück
            </span>
                    </div>

                    <div className="lg:col-span-2 pl-3 sm:max-h-[80%] min-h-0 flex-1 overflow-y-auto">

                        <div className="relative mb-8">
                            <AvailableDevices availableDevices={availableDevices} onDeviceSelect={handleSelectDevice}/>
                        </div>

                        {data.id && data.rule?.id && (
                            <div className="relative">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Wiederholungen
                                </h3>
                                <p className="mt-1 text-gray-500 py-2">
                                    Einzelne Wiederholungen absagen oder wiederherstellen:
                                </p>

                                <ul role="list" className="divide-y space-y-1">
                                    {data.future_occurrences &&
                                        data.future_occurrences.map((occ, index) => (
                                            <li key={index} className="flex py-2">
                                                <div
                                                    className={
                                                        "text-sm font-medium " +
                                                        (occ.cancelled ? "text-gray-400" : "text-gray-900")
                                                    }
                                                >
                                                    {occ.cancelled && <span>(Abgesagt) </span>}
                                                    {formatDateTime(occ.start)} - {formatDateTime(occ.end)}
                                                    {!occ.cancelled && (
                                                        <XCircleIcon
                                                            className="h-5 w-5 text-red-600 inline-flex ml-2 align-middle"
                                                            onClick={() => handelCancelOccurrence(occ)}
                                                        />
                                                    )}
                                                    {occ.cancelled && (
                                                        <CheckCircleIcon
                                                            className="h-5 w-5 text-green-800 inline-flex ml-2 align-middle"
                                                            onClick={() => handelUncancelOccurrence(occ)}
                                                        />
                                                    )}
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}
                    </div>

                </div>
            </Page>
        </>
    );
}
