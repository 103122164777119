import {toast} from "react-toastify";
import http from "./../services/httpService";

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export async function clearBackendCache() {
    const resp = await http.get('/utils/clear_cache/')
    console.log('clearBackendCache')
    toast.success("Backend-Cache geleert")
}

