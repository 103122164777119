import React, {useRef} from "react";
import {useMessenger} from "../../../contexts/MessengerContext";
import {Checkbox, TextArea} from "../../elements/forms/Inputs";
import {PaperClipIcon} from "@heroicons/react/20/solid";
import FileList from "./FileList";
import {DeleteButton} from "../../elements/DeleteButton";

export const ConversationFooter = () => {

    const fileUploaderRef = useRef();

    const {
        isFileAttachments,
        uploadFile,
        replyOn,
        messageData,
        changeMessageData,
        saveDraft,
        sendMessage,
        deleteDraft,
        currentTab
    } = useMessenger();

    const isVisible = currentTab === "Entwürfe" ? true : replyOn;
    if (!isVisible) return null;

    const messagePath = "message"
    const isSend = !!messageData.message && (currentTab === "Entwürfe" ?  (messageData.subject && !!messageData.draft_recipients?.length) : true)

    return (
        <div className="w-full relative flex flex-col p-2 sm:p-3 space-y-2 sm:space-y-3">
            <TextArea
                path={messagePath}
                errors={""}
                data={messageData}
                onChange={changeMessageData}
                inputClassName="shadow-sm focus:ring-imsblue-500 focus:border-imsblue-500 focus:outline-none block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder={"Nachricht schreiben..."}
                rows={3}
            />

            <div className="flex items-center justify-between w-full">
                {isFileAttachments && <div className="flex items-center justify-start">
                    <PaperClipIcon
                        className="cursor-pointer h-[38px] w-6"
                        onClick={() => fileUploaderRef.current.click()}
                    />
                    <input
                        type={"file"}
                        hidden
                        ref={fileUploaderRef}
                        onChange={(e) => uploadFile(e.target.files[0])}
                    />

                    <Checkbox
                        className="ml-4"
                        path="save_attachments_in_downloads"
                        label="Anhänge in Downloads speichern"
                        onChange={changeMessageData}
                        errors={""}
                        data={messageData}
                        hideLegend={true}
                    />

                </div>}

                <div className="flex items-center justify-start">
                    <button
                        type="button"
                        onClick={saveDraft}
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 ml-3"
                    >
                        Entwurf speichern
                    </button>
                    <button
                        type="button"
                        onClick={sendMessage}
                        disabled={!isSend}
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 ml-3"
                    >
                        Senden
                    </button>
                </div>
            </div>
            {isFileAttachments && <FileList/>}

            {messageData.id && messageData.sender_status === 'draft' &&
                <div className="mt-2">
                    <DeleteButton
                    onDelete={() => deleteDraft(messageData.id)}/>
            </div>}

        </div>
    )
}
