import {Page} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {Link, useNavigate, useParams} from 'react-router-dom'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {DateInput, Select, SelectForStudent} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {toast} from "react-toastify";
import {deleteProjectAttendance,} from "../../../api/projects";
import {getAttendedOptions} from "../../../api/utils";
import {formatTimeFromDateTime} from "../../../utils/formatters";
import {
    getAbsenceNotices,
    getStudentsForStudierzeitParams,
    getStudierzeitAttendance,
    getStudierzeitTimes,
    getStudierzeitTypes,
    patchStudierzeitAttendance,
    postStudierzeitAttendance
} from "../../../api/studierzeit";
import {getDayPeriods} from "../../../api/calendar";
import {getRooms, getStudierzeitRooms} from "../../../api/organizational";
import Alert from "../../elements/Alert";
import {AddButton} from "../../elements/Buttons";
import {getStudentInfoAll} from "../../../api/school";


export default function AttendanceCreateEdit({url}) {

    const {id} = useParams()
    const queryClient = useQueryClient()
    let navigate = useNavigate()
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [loading, setLoading] = useState(false)
    const [absenceNotices, setAbsenceNotices] = useState()
    const [attendanceLines, setAttendanceLines] = useState([])
    const [newRowId, setNewRowId] = useState(1)
    const [errors, setErrors] = useState({})

    const [data, setData] = useState({
        id: null,
        studierzeit_type: {id: parseInt(params.get('studierzeit_type')) || 1},
        studierzeit_time: {id: parseInt(params.get('studierzeit_time')) || null},
        room: params.get('room') ? {id: parseInt(params.get('room'))} : null,
        date: params.get('date')
    })

    const schema = {
        studierzeit_type: Joi.object().required(),
        studierzeit_time: Joi.object().required(),
        room: Joi.object().required(),
        date: Joi.date().required()
    }

    const [students, setStudents] = useState([])

    const {data: allStudents} = useQuery(["student_info"], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: rooms} = useQuery(["studierzeit_rooms"], getStudierzeitRooms, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: attendanceOptions} = useQuery(['attendance_options'], getAttendedOptions, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: studierzeitTypes} = useQuery(['studierzeit_types'], getStudierzeitTypes, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: studierzeitTimes} = useQuery(['studierzeit_times'], getStudierzeitTimes, {refetchInterval: 1000 * 60 * 60 * 24})

    let attendedOption = attendanceOptions ? attendanceOptions.find(option => option.name === 'anwesend') : null

    useEffect(() => {
        loadData()
    }, [id])

    useEffect(() => {
        loadStudents()
        // loadUrlParams()
    }, [data, attendanceOptions])

    useEffect(() => {
        loadAbsenceNotices()
    }, [students])

    async function loadAbsenceNotices() {
        if (!data.date || !students) return
        const studentIds = students.map(student => student.id)
        const a = await getAbsenceNotices(studentIds, data.date)
        setAbsenceNotices(a)
    }

    async function loadData() {
        let newData
        if (id) {
            newData = await getStudierzeitAttendance(id)
            setData(newData)
            setAttendanceLines(newData.studierzeit_attendance_lines)
        }
    }

    async function loadStudents() {

        if (!(data.date && data.studierzeit_type) || !attendanceOptions || (data.studierzeit_type?.code !== 'Strafe' && !data.room)) return
        setLoading(true)

        const newStudents = await getStudentsForStudierzeitParams(data)
        setStudents(newStudents)

        // add missing lines
        let studierzeit_attendance_lines = [...attendanceLines]

        for (var i = 0; i < newStudents.length; i++) {
            let student = newStudents[i]
            if (!studierzeit_attendance_lines.find(l => l.student.id == student.id)) {
                const newLine = {
                    id: null,
                    student: student,
                    attended: attendedOption
                }
                studierzeit_attendance_lines.push(newLine)
            }
        }

        setAttendanceLines(studierzeit_attendance_lines)
        setLoading(false)
    }

    function addRow() {
        const newRow = {
            id: `N${newRowId}`,
            student: {id: null},
            attended: null,
        }
        setNewRowId(newRowId + 1)
        setAttendanceLines([...attendanceLines, newRow])
    }

    async function onSave() {
        let newData

        let tmpAttendanceLines = attendanceLines.map((t, index) => {
            if (t.id && t.id.toString().startsWith("N")) {
                return {...t, id: null}
            }
            return {...t}
        })

        const sendData = {...data, studierzeit_attendance_lines: tmpAttendanceLines}
        // console.log('sendData', sendData)

        if (!data.id) newData = await postStudierzeitAttendance(sendData)
        else newData = await patchStudierzeitAttendance(sendData)
        setData(newData)
        setAttendanceLines(newData.studierzeit_attendance_lines)
        queryClient.invalidateQueries({ queryKey: ['studierzeit_attendance'] })
        toast.success("Studierzeitanwesenheit erfasst.")
        navigate(`/studierzeit/attendance/${newData.id}/homework`)
    }

    async function onDelete() {
        await deleteProjectAttendance(data.id)
        toast.success("Studierzeitanwesenheit gelöscht.")
        navigate(`/studierzeit/attendance`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
        // loadStudents()
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema)
        // loadStudents()
    }

    function handleAttendedChange(evt, rowId) {
        const newAttendanceLines = attendanceLines.map(row => {
            if (row.id === rowId) {
                var value = evt.target.value
                return {...row, [evt.target.name]: value}
            }
            return row
        })
        setAttendanceLines(newAttendanceLines)

    }

    function handleAttendedChangeForStudent(evt, studentId) {
        const newAttendanceLines = attendanceLines.map(row => {
            if (row.student.id === studentId) {
                var value = evt.target.value
                return {...row, [evt.target.name]: value}
            }
            return row
        })
        setAttendanceLines(newAttendanceLines)

    }

    const title = (id || data.id) ? "Studierzeitanwesenheit bearbeiten" : "Neue Studierzeitanwesenheit erfassen"
    const successMessage = (id || data.id) ? "Studierzeitanwesenheit aktualisiert" : "Studierzeitanwesenheit erfasst"

    return (

        <Page title={title}>

            <div
                className=" max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="notes-title">

                        <Form id="createProjectParticipation" onSave={onSave}
                              onDelete={onDelete}
                              data={data} setData={setData}
                              className=""
                              errors={errors} setErrors={setErrors}
                              successMessage={successMessage}
                              schema={schema}>

                            <FormSectionContent>

                                <DateInput
                                    className="col-span-3"
                                    path="date"
                                    label="Datum"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={false}
                                    tomorrowButton={false}
                                />

                                {/*<Select*/}
                                {/*    path="time"*/}
                                {/*    label="Stunde"*/}
                                {/*    className="sm:col-span-3"*/}
                                {/*    options={dayPeriods}*/}
                                {/*    valueAttr="id"*/}
                                {/*    labelAttr="name"*/}
                                {/*    onChange={handleChange}*/}
                                {/*    errors={errors}*/}
                                {/*    data={data}*/}
                                {/*    isClearable={false}*/}
                                {/*    valueOnly={false}*/}
                                {/*/>*/}

                                <Select
                                    path="studierzeit_type"
                                    label="Art"
                                    className="sm:col-span-3"
                                    options={studierzeitTypes}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={false}
                                />

                                <Select
                                    path="studierzeit_time"
                                    label="Studierzeitblock"
                                    className="sm:col-span-3"
                                    options={studierzeitTimes}
                                    valueAttr="id"
                                    labelAttr="__str__"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />

                                <Select
                                    path="room"
                                    label="Raum"
                                    className="sm:col-span-3"
                                    options={rooms}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />

                                <div className="col-span-6 divide-y divide-gray-200">
                                    <div className="py-5 flex justify-between">
                                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                            Anwesenheit
                                        </h2>
                                    </div>
                                    <div className="">

                                        {loading && <Alert variant="info">SchülerInnen werden geladen...</Alert>}

                                        <div
                                            className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                                            {attendanceLines &&

                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <tbody>
                                                    {attendanceLines.map((l, index) => (
                                                        <tr key={index}
                                                            className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                                        >
                                                            <td className="py-4 pl-4 pr-3 text-sm">
                                                                {(!l.id || !l.id.toString().startsWith("N")) && l.student.full_name}

                                                                {l.id && l.id.toString().startsWith("N") && <Select
                                                                    path="student"
                                                                    label="SchülerIn"
                                                                    className="sm:col-span-6 z-50 hide-label"
                                                                    onChange={handleAttendedChange}
                                                                    options={allStudents}
                                                                    labelAttr="full_name"
                                                                    valueAttr="id"
                                                                    valueOnly={false}
                                                                    data={attendanceLines}
                                                                    rowId={l.id}
                                                                />}
                                                            </td>

                                                            <td className="py-4 pl-4 pr-3 text-sm">
                                                                {(!l.id || !l.id.toString().startsWith("N")) && <SelectForStudent
                                                                    path="attended"
                                                                    label="Anwesend"
                                                                    className="sm:col-span-6 z-50 hide-label"
                                                                    onChange={handleAttendedChangeForStudent}
                                                                    options={attendanceOptions}
                                                                    labelAttr="name"
                                                                    valueAttr="id"
                                                                    valueOnly={false}
                                                                    data={attendanceLines}
                                                                    studentId={l.student.id}
                                                                />}

                                                                {l.id && l.id.toString().startsWith("N") && <Select
                                                                    path="attended"
                                                                    label="Anwesend"
                                                                    className="sm:col-span-6 z-50 hide-label"
                                                                    onChange={handleAttendedChange}
                                                                    options={attendanceOptions}
                                                                    labelAttr="name"
                                                                    valueAttr="id"
                                                                    valueOnly={false}
                                                                    data={attendanceLines}
                                                                    rowId={l.id}
                                                                />}
                                                            </td>

                                                        </tr>
                                                    ))}

                                                    </tbody>
                                                </table>
                                            }

                                            <div className="p-4"><AddButton onClick={addRow}/></div>
                                        </div>
                                    </div>

                                </div>

                            </FormSectionContent>

                        </Form>

                        <Link to={`/studierzeit/list`}
                              className="float-right mt-2 font-medium text-imsblue-600">Zurück zur
                            Liste</Link>
                    </section>

                </div>

                <section aria-labelledby="timeline-title"
                         className="lg:col-start-3 lg:col-span-1 mt-6"
                >
                    <section aria-labelledby="applicant-information-title" className="bg-gray-100">

                        <div className="">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Abwesenheitsmeldungen
                                </h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">

                                {absenceNotices && absenceNotices.absence_notices &&
                                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {absenceNotices.absence_notices.map((a, index) => (
                                            <tr key={index}>
                                                <td className="px-4 py-4">
                                                    {a.absence_notice.student.full_name}
                                                </td>
                                                <td className="py-4">
                                                    <>{formatTimeFromDateTime(a.occurrence.start)} - {formatTimeFromDateTime(a.occurrence.end)}</>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>}

                            </div>
                        </div>

                    </section>

                </section>
            </div>


        </Page>
    )
}
