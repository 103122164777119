import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {CalendarButton, DownloadButton, EditButton, PrimaryButton} from "../../elements/Buttons";
import {examApi, getExamsExcel} from "../../../api/school";
import checkPermission from "../../../utils/permissions";

const columns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
    },
    {
        accessorKey: "time",
        id: "time",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "room_bookings",
        id: "room_bookings",
        header: "Raum",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <>
                {row.original.room_bookings.map((r, index) => (
                    <span key={index}>
                        {index > 0 ? ", " : ""}
                        {r.room.name}
                    </span>
                ))}
            </>
        ),
    },
    {
        accessorKey: "category",
        id: "category",
        header: "Art",
        enableColumnFilter: false,
    },
    {
        accessorKey: "topic",
        id: "topic",
        header: "Thema",
        enableColumnFilter: false,
        headerClassNames: "medium-filter"
    },
    {
        accessorKey: "courses",
        id: "courses",
        header: "Kurs",
        columnType: "m2mLinked",
        getItemLink: (item) => {
            return `/courses/${item.id}/`
        },
        labelAttr: "course_number",
        enableColumnFilter: true,
        filterFn: "containsString",
    },
    {
        accessorKey: "courses",
        id: "courses",
        header: "Fach",
        // columnType: "m2m",
        cell: ({row, value}) => (
            row.original.courses?.map((c) =>
                    c.school_subject?.name).join(", ")
        ),
        enableColumnFilter: false,
    },
    {
        accessorKey: "teachers",
        id: "teachers",
        header: "Lehrer",
        columnType: "m2mLinked",
        getItemLink: (item) => {
            return `/staff/${item.id}/`
        },
        labelAttr: "full_name",
        enableColumnFilter: true,
        filterFn: "containsString",
    },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            <>
                <EditButton iconOnly={true} link={`/class-register/exams/${row.original.id}/edit`}/>
            </>
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
        disableRowLink: true
    }
];

export default function ExamList() {

    const {permissions} = useAuth();

    const {isLoading, isSuccess, data} = useQuery(
        ["exams"],
        () => examApi('get'),
        {refetchInterval: 1000 * 60 * 60}
    )

    function getRowLink(row) {
        return `/class-register/exams/${row.original.id}/`;
    }

    const canAddExam = checkPermission(permissions, "school.add_exam")

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Klausuren
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">

                        <DownloadButton
                            apiFunction={() => getExamsExcel()}
                            fileName="Klausuren.xls"
                            label="Liste herunterladen"/>

                        <CalendarButton link={"/class-register/exams/calendar"} label="Kalender"/>

                        {canAddExam && <PrimaryButton link={"/class-register/exams/new"} label="Klausur eintragen"/>}

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
