import http from "../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";

export async function getCertificateRemarks(assessmentPeriodId, classTeacherId) {
    const resp = await http.get(`/certificate_remarks/students/?assessment_period_id=${assessmentPeriodId}`
        + (classTeacherId ? `&class_teacher_id=${classTeacherId}` : '')
    )
    return resp.data
}

export async function updateMultipleCertificateRemarks(data) {
    const resp = await http.post('/certificate_remarks/update_multiple/', data)
    return resp.data
}

export async function getCertificate(assessmentPeriodId, studentId) {
    const resp = await httpServiceDownloads.get(`/certificates/${assessmentPeriodId}/students/${studentId}/`)
    return resp.data
}
export async function getTransferCertificate(assessmentPeriodId, studentId) {
    const resp = await httpServiceDownloads.get(`/certificates/${assessmentPeriodId}/students/${studentId}/transfer/`)
    return resp.data
}


export async function generateMultipleCertificates(assessmentPeriodId, schoolClassId, data) {
    const resp = await http.post(`/certificates/${assessmentPeriodId}/generate/` + (schoolClassId ? `?school_class_id=${schoolClassId}` : ''), data)
    return resp.data
}


export async function getCertificateRemarksAndFinalConductGrades(assessmentPeriodId, studentId) {
    const resp = await http.get(`/certificates/${assessmentPeriodId}/students/${studentId}/certificate_remarks_and_final_conduct_grades/`)
    return resp.data
}

export async function updateCertificateRemarksAndFinalConductGrades(assessmentPeriodId, studentId, data) {
    const resp = await http.post(`/certificates/${assessmentPeriodId}/students/${studentId}/certificate_remarks_and_final_conduct_grades/`, data)
    return resp.data
}

export async function getMovingUpStatus(assessmentPeriodId) {
    const resp = await http.get(`/moving_up_status/?assessment_period_id=${assessmentPeriodId}`)
    return resp.data
}

export async function getClassTeachers(assessmentPeriodId) {
    const resp = await http.get(`/class_teachers/?assessment_period_id=${assessmentPeriodId}`)
    return resp.data
}

export async function getSchoolClassesForCertificates(assessmentPeriodId) {
    const resp = await http.get(`/certificates/${assessmentPeriodId}/school_classes/`)
    return resp.data
}