import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import checkPermission, {checkGroup} from "../../../utils/permissions";
import {Link, useParams} from "react-router-dom";
import {classNames} from "../../../utils/misc";
import {getStatusOptions, getTutorings, updateTutoringStatuses} from "../../../api/tutoring";
import {Select} from "../../elements/forms/Inputs";
import {PrimaryButton} from "../../elements/Buttons";
import {toast} from "react-toastify";
import {formatDate} from "../../../utils/formatters";
import {baseHandleListChange} from "../../elements/forms/Form";
import env from "react-dotenv";


export default function TutoringList() {
    const {permissions, currentUser} = useAuth();
    const {studentId} = useParams();

    const [errors, setErrors] = useState([]) // validation errors
    const [data, setData] = useState()

    const [allOrCurrent, setAllOrCurrent] = useState("current");
    // const [rowSelection, setRowSelection] = useState([]);

    useEffect(() => {
        loadData()
    }, [allOrCurrent, studentId])

    async function loadData() {
        const newData = await getTutorings(allOrCurrent, studentId)
        setData(newData)
    }

    const {data: statusOptions} = useQuery(
        ["tutoring_status_options"],
        () => getStatusOptions(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const canChangeTutoring = checkPermission(permissions, "tutoring.change_tutoring")
    const canRequestTutoring = checkGroup("Eltern", currentUser)

    let columns = [
        {
            accessorFn: (row) =>
                `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            classNames: "min-w-lg white-space-pre-line",
            enableColumnFilter: false,
        },
        {
            accessorKey: "student",
            id: "student",
            header: "SchülerIn",
            columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "subject.name",
            id: "subject",
            header: "Fach",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter",
            filterFn: "containsString",
            labelAttr: "name",
        },
        ... canChangeTutoring? [{
            accessorKey: "jugendamt",
            id: "jugendamt",
            header: "Jugendamt",
            columnType: "yesno",
            enableColumnFilter: true,
            filterFn: "boolean",
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter"
        }] : [],
        {
            accessorKey: "teacher.full_name",
            id: "teacher",
            header: "Fachlehrer",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "medium-filter",
            labelAttr: "full_name",
            filterFn: "containsString"
        },
        {
            accessorKey: "tutoring_teacher.full_name",
            id: "tutoring_teacher",
            header: "Nachhilfelehrer",
            enableColumnFilter: true,
            filterFn: "containsString",
            labelAttr: "full_name",
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "tutoring_plan_filled_in",
            id: "tutoring_plan_filled_in",
            header: "Förderplan",
            enableColumnFilter: true,
            columnType: "yesno",
            filterFn: "boolean",
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter",
        },
        // {
        //     accessorKey: "get_requested_by_display",
        //     id: "get_requested_by_display",
        //     header: "Beantragt von",
        //     enableColumnFilter: true,
        //     // classNames: 'font-medium text-gray-900'
        //     classNames: "text-gray-500",
        //     headerClassNames: "narrow-filter",
        // },
        {
            accessorKey: "get_status_display",
            id: "status",
            header: "Status",
            enableColumnFilter: true,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
            headerClassNames: "medium-filter",
            disableRowLink: true,
            cell: ({row, value}) => (<>

                {canChangeTutoring ?
                <Select
                    path="status"
                    label="Status"
                    className="w-52"
                    options={statusOptions}
                    valueAttr="value"
                    labelAttr="label"
                    onChange={handleChange}
                    errors={errors}
                    data={data}
                    rowId={row.original.id}
                    isClearable={false}
                    valueOnly={true}
                    disabled={!canChangeTutoring}
                /> : <>{row.original.get_status_display}</>}

                {row.original.status === 'CANCELLED' && row.original.date_cancellation_effective &&
                    <span> zum {formatDate(row.original.date_cancellation_effective)}</span>}
                {row.original.status === 'REJECTED' &&
                    <span> von {row.original.get_rejected_by_display} am {formatDate(row.original.date_rejected)}</span>}

            </>)
        },
        ... canChangeTutoring? [{
            // intentionally not using a date type column so the filter can have a selection rather than date range
            accessorFn: (row) => `${formatDate(row.date_cancellation_effective)}`,
            id: "date_cancellation_effective",
            header: "Gekündigt zum",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter",
        }] : [],

    ]

    function handleChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data, setData, errors, setErrors, {})
    }

    async function handleSave() {
        try {
            const newData = await updateTutoringStatuses(data)
            loadData()
            // setData(newData)
            toast.success("Status gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    function getRowLink(row) {
        return `/tutoring/${row.original.id}/`;
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Fördermaßnahmen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {checkPermission(
                            permissions,
                            "tutoring.add_tutoring"
                        ) && (
                            <Link to="/tutoring/new">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Neue Fördermaßnahme
                                </button>
                            </Link>
                        )}

                        {canRequestTutoring && env.SCHOOL === 'KPI' &&
                            <Link to="/tutoring/request">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Fördermaßnahme beantragen
                                </button>
                            </Link>}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                <a
                                    key="current"
                                    onClick={() => setAllOrCurrent("current")}
                                    className={classNames(
                                        allOrCurrent === "current"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={allOrCurrent === "current" ? "page" : undefined}
                                >
                                    Aktuelle
                                </a>
                                <a
                                    key="today"
                                    onClick={() => setAllOrCurrent("all")}
                                    className={classNames(
                                        allOrCurrent === "all"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={allOrCurrent === "all" ? "page" : undefined}
                                >
                                    Alle
                                </a>
                            </nav>
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (

                                <>
                                    {canChangeTutoring && <PrimaryButton
                                        classNameIn="float-right"
                                        onClick={handleSave} label="Speichern"/>}

                                    <ListViewTable
                                        data={data}
                                        columns={columns}
                                        isLoading={!data}
                                        disableGlobalSearch={false}
                                        rowSelection={null}
                                        setRowSelection={null}
                                        getRowLink={getRowLink}
                                        onDelete={null}
                                    />

                                    {canChangeTutoring && <PrimaryButton
                                        classNameIn="float-right mt-4"
                                        onClick={handleSave} label="Speichern"/>}


                                </>

                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
