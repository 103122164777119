import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getCourse} from "../../../api/courses";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {getAndGenerateAssessments} from "../../../api/assessments";
import AssessmentDetail from "./AssessmentDetail";


export default function CourseDetail() {

    const {assessmentPeriodId, courseId} = useParams()

    const {data: course} = useQuery(
        ["course_detail", courseId],
        () => getCourse(courseId)
    )

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["assessments", assessmentPeriodId, courseId],
        () => getAndGenerateAssessments(assessmentPeriodId, courseId, 1, 0),
        {refetchInterval: 1000 * 60 * 60}
    )

    function getRowLink(row) {
        return `/assessments/assessment-periods/${assessmentPeriodId}/courses/${courseId}/edit/${row.index}`;
    }

    const columns = [
        {
            accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name}`,
            id: "student_search",
            header: "SchülerIn",
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => (row.finalized ? 'abgeschlossen' : 'ausstehend'),
            id: "status",
            header: "Status",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <TableEditLink
                    iconOnly={true}
                    label="Bearbeiten"
                    to={`/assessments/assessment-periods/${assessmentPeriodId}/courses/${courseId}/edit/${row.index}`}
                />
            ),
            header: "",
            disableRowLink: true,
            enableColumnFilter: false,
        },
    ];

    if (!course) return <></>

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Beurteilungen für {course.course_number} {course.school_subject.name}
                            {course.school_year.is_current_school_year ? '' : ` (${course.school_year.name})`}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">

                        <SecondaryButton link={`/courses/${courseId}`} label="Kursansicht" />

                        {data?.can_edit_assessments && (
                            <PrimaryButton link={`/assessments/assessment-periods/${assessmentPeriodId}/courses/${courseId}/edit`} label="Bearbeiten"/>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data.assessments}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={true}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg-gray-100 pt-6 mt-8 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Ansicht
                        </h3>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8 max-w-6xl">
                            {data && data.assessments.map((a, index) => (
                                <AssessmentDetail assessment={a} inCourse={true} key={index} index={index} canEdit={data.can_edit_assessments} />
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
