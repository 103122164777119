import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import {useQuery} from '@tanstack/react-query'
import {RadioGroup} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {useNavigate} from "react-router-dom";
import {getQuizBase, getQuizOptions, postQuizResult} from "../../../api/user_quiz";
import {PrimaryButton} from "../../elements/Buttons";
import {useUserInfo} from "../../../contexts/UserInfoContext";
import {toast} from "react-toastify";
import {classNames} from "../../../utils/misc";


function getFilteredQuizBase(quizBase, mode) {
    switch (mode) {
        case "STAFF":
            return quizBase.filter(item => item.role === "MitarbeiterIn");
        case "STUDENTS":
            return quizBase.filter(item => item.role === "SchülerIn");
        default:
            return quizBase
    }
}

function getRandomItems(quizBase, mode, numberOfQuestions) {

    let filteredQuizBase = getFilteredQuizBase(quizBase, mode)
    const shuffled = filteredQuizBase.sort(() => 0.5 - Math.random());

    let quizUsers = shuffled
    // only if numberOfQuestions is a number
    if (typeof numberOfQuestions === 'number') {
        quizUsers = shuffled.slice(0, numberOfQuestions);
    }
    return {
        quizUsers: quizUsers,
        filteredQuizBase: filteredQuizBase
    }
}

function addAnswerOptions(quizUsers, quizBase) {
    quizUsers.forEach(user => {
        const correctFullName = user.full_name;
        const otherFullNames = quizBase
            .map(item => item.full_name)
            .filter(fullName => fullName !== correctFullName);

        // Randomly pick 3 unique full names from otherFullNames
        let randomOtherFullNames;
        if (otherFullNames.length > 3) {
            randomOtherFullNames = [];
            while (randomOtherFullNames.length < 3) {
                const randomIndex = Math.floor(Math.random() * otherFullNames.length);
                const selectedName = otherFullNames[randomIndex];
                if (!randomOtherFullNames.includes(selectedName)) {
                    randomOtherFullNames.push(selectedName);
                }
            }
        } else {
            randomOtherFullNames = otherFullNames;
        }

        // Combine the correct full name with the 3 random ones
        const answerOptions = [correctFullName, ...randomOtherFullNames];

        // Shuffle the answerOptions
        for (let i = answerOptions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [answerOptions[i], answerOptions[j]] = [answerOptions[j], answerOptions[i]]; // Swap
        }

        // Assign the shuffled answerOptions back to the user
        user.answerOptions = answerOptions;
    });

    return quizUsers;
}

export default function QuizRun() {

    const [quizOpen, setQuizOpen] = useState(false)

    const [data, setData] = useState({
        mode: "ALL",
        number_of_questions: 10
    })
    const [errors, setErrors] = useState({}) // validation errors

    const [quizReady, setQuizReady] = useState(false)
    const [resultSaved, setResultSaved] = useState(false)
    const [currentQuizQuestions, setCurrentQuizQuestions] = useState([])
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [isStudyMode, setIsStudyMode] = useState(false)

    const numberOfQuestions = [
        {value: 10, label: "10"},
        {value: 25, label: "25"},
        {value: 100, label: "100"},
        {value: "ALL", label: "Alle"}
    ]

    let navigate = useNavigate();

    const {data: quizOptions} = useQuery(
        ["quiz_options"],
        () => getQuizOptions(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {data: quizBase} = useQuery(
        ["quiz_base"],
        () => getQuizBase(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )


    const schema = {}

    const title = "Bilderquiz"
    const successMessage = "Quiz abgeschlossen & gespeichert"

    const quizCompleted = currentQuestionIndex === data.number_of_questions

    async function saveResult() {
        const payload = {
            quiz_mode: data.mode,
            correct_answers: currentQuizQuestions.filter(question => question.correct === true).length,
            total_questions: currentQuizQuestions.length
        }
        const newData = await postQuizResult(payload)
        // setData(newData)
        setResultSaved(true)
        toast.success(successMessage)
    }

    useEffect(() => {
        if (quizCompleted && !resultSaved && !isStudyMode) {
            saveResult()
        }
    }, [quizCompleted]);

    function prepareQuiz() {

        // from quizBase, pick random users based on number of questions and mode
        var {quizUsers, filteredQuizBase } = getRandomItems(quizBase, data.mode, data.number_of_questions)

        // now add 3 wrong answers (i.e. additional names from the quizBase) for each of these
        var quizQuestions = addAnswerOptions(quizUsers, filteredQuizBase)

        setCurrentQuizQuestions(quizQuestions)
        setQuizOpen(true)
    }


    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function selectAnswer(questionIndex, answer) {

        const correctAnswer = currentQuizQuestions[questionIndex].full_name
        var correctAnswerGiven = false

        if (answer === correctAnswer) {
            correctAnswerGiven = true
        }

        setCurrentQuizQuestions(currentQuizQuestions.map((question, index) => {
            if (index === questionIndex) {
                return {...question, correct: correctAnswerGiven, answer: answer}
            } else {
                return question
            }
        }))
    }

    function startQuiz() {
        prepareQuiz()
        setIsStudyMode(false)
        setQuizReady(true)
    }

    function startStudy() {
        console.log('starting study')
        // todo
        setIsStudyMode(true)
        prepareQuiz()
        setQuizReady(true)

    }

    function reset() {

        setData({
            mode: "ALL",
            number_of_questions: 10
        })

        setQuizReady(false)
        setCurrentQuizQuestions([])
        setCurrentQuestionIndex(0)
        setQuizOpen(false)
    }

    // if (!data) return <></>
    return (

        <NarrowPage title={title}>

            {!quizOpen && <Form id="userImageQuiz" onSave={() => {
            }}
                                data={data} setData={setData}
                                errors={errors} setErrors={setErrors}
                                successMessage={successMessage}
                                hideSubmitButton={true}
                                hideFormLayout={false}
                                schema={schema}>

                <FormSectionContent>
                    <RadioGroup
                        key={"mode"}
                        path="mode"
                        label="Modus"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        options={quizOptions?.modes}
                        valueAttr={"value"}
                        labelAttr={"label"}
                        valueOnly={true}
                        tiles={true}
                    />

                    <RadioGroup
                        key={"number_of_questions"}
                        path="number_of_questions"
                        label="Anzahl Fragen"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        options={numberOfQuestions}
                        valueAttr={"value"}
                        labelAttr={"label"}
                        valueOnly={true}
                        tiles={true}
                    />
                    <div className="sm:col-span-6 mt-2 flex gap-2 justify-center justify-items-center">
                        <PrimaryButton onClick={startQuiz} label="Quiz starten"/>
                        <PrimaryButton onClick={startStudy} label="Lernmodus"/>
                    </div>


                </FormSectionContent>

            </Form>}

            {/* Questions */}
            {quizOpen && quizReady && !quizCompleted &&
                <div className="mt-4">

                    {/* Questions */}
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>
                            Frage {currentQuestionIndex + 1} von {currentQuizQuestions.length}
                        </p>
                    </div>
                    {currentQuizQuestions.map((question, index) => (
                        <Question key={index}
                                  index={index}
                                  isStudyMode={isStudyMode}
                                  currentQuestionIndex={currentQuestionIndex}
                                  question={currentQuizQuestions[currentQuestionIndex]}
                                  selectAnswer={(answer) => selectAnswer(currentQuestionIndex, answer)}
                        />
                    ))}
                    <div className="mt-4 text-center flex flex-col gap-4">
                        <PrimaryButton onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
                                       label={"Weiter"}/>

                        <span onClick={reset}
                              className="mt-10 cursor-pointer float-right mt-2 font-medium text-imsblue-600">Neu starten</span>
                    </div>

                </div>
            }

            {/* Result */}
            {quizCompleted && <Result currentQuizQuestions={currentQuizQuestions} reset={reset} isStudyMode={isStudyMode} />}

            {/*<QuizStatusHelper currentQuizQuestions={currentQuizQuestions}/>*/}


        </NarrowPage>
    )
}


function Question({
                      index,
                      question,
                      selectAnswer,
                      currentQuestionIndex,
                      isStudyMode
                  }) {

    const {renderUserImage} = useUserInfo();

    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [showAnswer, setShowAnswer] = useState(false)

    function handleSelectAnswer(answer) {
        setSelectedAnswer(answer)
        selectAnswer(answer)
    }

    if (index !== currentQuestionIndex) return null

    return (

        <div className="">
            <h2 className="text-xl font-medium leading-6 text-gray-900">Wer ist das?</h2>

            <div className="flex justify-center my-10">
                <div className="flex-shrink-0">
                    <div className="relative">
                        {renderUserImage(question.id, 120)}
                        <span
                            className="absolute inset-0 shadow-inner rounded-full"
                            aria-hidden="true"
                        />
                    </div>
                </div>
            </div>

            {!isStudyMode && <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {question.answerOptions.map((answerOption, index) => (
                    <div
                        key={index}
                        className={"bg-white overflow-hidden shadow rounded-lg "
                            + (selectedAnswer !== null && answerOption === question.full_name ? "border-4 border-green-500" : "border-2 border-gray-200") + " cursor-pointer hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            + (selectedAnswer === answerOption && selectedAnswer !== question.full_name ? "border-4 border-red-500" : "border-2 border-gray-200") + " cursor-pointer hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        }
                        onClick={() => handleSelectAnswer(answerOption)}
                    >
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-md font-medium leading-6 text-gray-900">{answerOption}</h3>
                            {selectedAnswer !== null && answerOption === question.full_name &&
                                <p>{question.role === 'SchülerIn' ? question.school_class : question.staff_roles}</p>}
                        </div>
                    </div>
                ))}
            </div>}

            {isStudyMode && <div className="">

                {showAnswer && <div className={"bg-white overflow-hidden shadow rounded-lg"}>
                    <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-md font-medium leading-6 text-gray-900">{question.full_name}</h3>
                        <p>{question.role === 'SchülerIn' ? question.school_class : question.staff_roles}</p>
                    </div>
                </div>}

                <button type="button"
                        className={classNames("w-full mt-4 cursor-pointer inline-flex items-center " +
                            "justify-center px-4 py-2 border border-transparent text-sm font-medium " +
                            "rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 " +
                            "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
                        )}
                        onClick={() => setShowAnswer(true)}
                >Antwort zeigen
                </button>

            </div>}


        </div>
    )

}

function QuizStatusHelper({currentQuizQuestions}) {

    return (
        <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
                Helfer
            </p>

            <ul>
                {currentQuizQuestions.map((question, index) => (
                    <li key={index}>
                        {index}.
                        {question.full_name} - {question.correct === true ? "richtig" : "falsch"} - {question.answer}
                    </li>
                ))}
            </ul>

        </div>
    )
}

function Result({currentQuizQuestions, reset, isStudyMode}) {

    const total = currentQuizQuestions.length
    const correct = currentQuizQuestions.filter(question => question.correct === true).length
    const percentage = Math.round((correct / total) * 100)

    const resultGif = ({percentage}) => {

        if (percentage < 30) {
            return "/img/fail.gif"
        } else if (percentage < 75) {
            return "/img/okay.gif"
        } else {
            return "/img/success.gif"
        }

    }

    return (
        <div className="mt-4">
            {!isStudyMode && <div className="mt-2 w-full text-gray-500 text-center">
                <h2 className="text-xl font-medium leading-6 text-gray-900 mb-2">Ergebnis</h2>
                <img src={resultGif({percentage})} className="mx-auto"/>
                <div className="text-center mt-5 ">
                    <span className="text-3xl font-semibold tracking-tight text-gray-900">{percentage}%</span>
                    <br/>
                    <span>{correct} von {total} Fragen richtig beantwortet</span>
                </div>
            </div>}
            {isStudyMode && <div className="mt-2 w-full text-gray-500 text-center">
                <h2 className="text-xl font-medium leading-6 text-gray-900 mb-2">Lernmodus abgeschlossen</h2>
            </div>}
            <div className="mt-5 text-center">
                <PrimaryButton onClick={reset} label="Neu starten"/>
            </div>
        </div>

    )
}