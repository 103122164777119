import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableDownloadLink} from "../../elements/tables/ListViewTable";
import {assessmentPeriodApi, getStudentsForAssessmentPeriodPost} from "../../../api/assessments";
import {useParams} from "react-router-dom";
import {AsyncDownloadButton} from "../../elements/Buttons";
import {generateMultipleCertificates, getCertificate, getTransferCertificate} from "../../../api/certificates";


export default function GenerateCertificates() {

    const {assessmentPeriodId, schoolClassId} = useParams()
    const [rowSelection, setRowSelection] = useState({});

    const {data: assessmentPeriod} = useQuery(
        ["assessment_periods", assessmentPeriodId],
        () => assessmentPeriodApi('get', assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["students_for_assessment_period_post", assessmentPeriodId, schoolClassId],
        () => getStudentsForAssessmentPeriodPost(assessmentPeriodId, schoolClassId),
        {refetchInterval: 1000 * 60 * 60}
    );

    function getRowLink(row) {
        return `/assessments/assessment-periods/${assessmentPeriodId}/students/${row.original.id}/`
    }

    const columns = [
        {
            accessorKey: "id",
            id: "select",
            columnType: "select",
        },
        {
            accessorFn: (row) =>
                `${row.last_name}, ${row.first_name} ${row.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => row,
            id: 'student',
            header: 'SchülerIn',
            columnType: 'student',
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "grade",
            id: "grade",
            header: "Stufe",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "school_class",
            id: "school_class",
            header: "Klasse",
            sortingFn: "schoolClassSorting",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        // {
        //     accessorKey: "house",
        //     id: "house",
        //     header: "Mentorat",
        //     enableColumnFilter: true,
        //     headerClassNames: "narrow-filter",
        // },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <div className="flex gap-2 justify-between">
                    <TableDownloadLink
                        label="Zeugnis"
                        fileName={`Zeugnis ${row.original.full_name}.pdf`}
                        apiFunction={() => getCertificate(assessmentPeriodId, row.original.id)}/>
                    <TableDownloadLink
                        label="(Ü)"
                        fileName={`Übergangszeugnis ${row.original.full_name}.pdf`}
                        apiFunction={() => getTransferCertificate(assessmentPeriodId, row.original.id)}/>
                </div>
            ),
            header: "Zeugnis",
            enableColumnFilter: false,
            disableRowLink: true
        },
    ];

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Zeugnisse {assessmentPeriod?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">


                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>

                    <div className="mt-4 flex gap-2 justify-start">

                        {Object.keys(rowSelection).length > 0 && <AsyncDownloadButton
                            classNameIn=""
                            apiFunction={() => generateMultipleCertificates(assessmentPeriodId, schoolClassId, rowSelection)}
                            label={`Zeugnisse für ${Object.keys(rowSelection).length} ausgewählte Schüler generieren`}/>}

                        <AsyncDownloadButton
                            classNameIn=""
                            apiFunction={() => generateMultipleCertificates(assessmentPeriodId, schoolClassId, {})}
                            label={`Alle Zeugnisse generieren`}/>

                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
