import {Link, useParams} from "react-router-dom";
import {React} from "react";
import {getLogbookEntryCategories, getLogbookStudentDetail, getLogbookStudentSummaries,} from "../../../api/logbook";
import {useQuery} from "@tanstack/react-query";
import {getStudentInfo} from "../../../api/school";
import {ExportPDFButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {UserName} from "../../elements/UserInfo";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import moment from "moment";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {useUserInfo} from "../../../contexts/UserInfoContext";

export default function StudentDetail() {
    const {id, categoryId} = useParams();
    const {permissions} = useAuth();

    const {data: student} = useQuery(["student_info", id], () =>
        getStudentInfo(id), {refetchInterval: 1000 * 60 * 60 * 24}
    );
    const {data: logbookEntries} = useQuery(
        ["logbook_student_detail", id, categoryId],
        () => getLogbookStudentDetail(id, categoryId)
    );
    const {data: summaries} = useQuery(["logbook_student_summaries", id], () =>
        getLogbookStudentSummaries(id)
    );

    const exportPDFData = <StudentDetailData
        id={id}
        student={student}
        summaries={summaries}
        permissions={permissions}
        logbookEntries={logbookEntries}
        isExportable={true}
    />

    if (!student) return <></>;
    return (
        <StudentDetailData
            id={id}
            student={student}
            summaries={summaries}
            permissions={permissions}
            logbookEntries={logbookEntries}
            exportPDFData={exportPDFData}
        />
    );
}

function StudentDetailData({id, student, summaries, permissions, logbookEntries, exportPDFData, isExportable}) {

    return (
        <main className={`py-10 h-full ${!isExportable && `bg-gray-100`}`}>
            <StudentDetailDataHeader
                id={id}
                student={student}
                permissions={permissions}
                exportPDFData={exportPDFData}
                isExportable={isExportable}
            />

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    {/* Logbucheinträge */}
                    <section aria-labelledby="notes-title">
                        <StudentLogbookEntries
                            logbookEntries={logbookEntries?.results}
                            student={student}
                            permissions={permissions}
                            isExportable={isExportable}
                        />
                    </section>

                    {/* Table */}
                    <section aria-labelledby="applicant-information-title"></section>
                </div>

                <section
                    aria-labelledby="timeline-title"
                    className="lg:col-start-3 lg:col-span-1"
                >
                    <section aria-labelledby="applicant-information-title">
                        {summaries && (
                            <PenaltySummary
                                student={student}
                                summaries={summaries}
                                permissions={permissions}
                                isExportable={isExportable}
                            />
                        )}
                    </section>
                </section>
            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                {/* Strafeinträge */}
                <section aria-labelledby="notes-title">
                    {summaries && (
                        <PenaltyEntries
                            penaltyEntries={summaries.penalty_entries}
                            student={student}
                            permissions={permissions}
                        />
                    )}
                </section>
            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                {/* Strafen */}
                <section aria-labelledby="notes-title">
                    {summaries && (
                        <Penalties
                            penalties={summaries.penalties}
                            student={student}
                            permissions={permissions}
                            isExportable={isExportable}
                        />
                    )}
                </section>
            </div>
        </main>
    )
}

function StudentDetailDataHeader({id, student, permissions, exportPDFData, isExportable}) {

    const {renderUserImage} = useUserInfo();
    return (
        <div
            className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
                <div className="flex-shrink-0">
                    <div className="relative">
                        {renderUserImage(student.user_id, 80)}
                        <span
                            className="absolute inset-0 shadow-inner rounded-full"
                            aria-hidden="true"
                        />
                    </div>
                </div>
                <div>
                    <h1 className="text-2xl font-bold text-gray-900">
                        {student.full_name}
                    </h1>
                    <p className="text-sm font-medium text-gray-500">
                        {student.student_number}
                    </p>
                </div>
            </div>
            {!isExportable && <div
                className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <SecondaryButton label="Schülerblatt" link={`/school/students/${id}`}/>

                {checkPermission(
                    permissions,
                    "student_logbook.add_logbookentry"
                ) && (
                    <PrimaryButton
                        link={`/logbook/entries/new/${id}`}
                        label="Neuer Logbucheintrag"
                    />
                )}

                <ExportPDFButton
                    data={exportPDFData}
                    fileName={`Logbuch - ${student.full_name}.pdf`}
                    label="PDF exportieren"
                />

            </div>}
        </div>
    )
}

function PenaltySummary({student, summaries, permissions, isExportable}) {
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Strafeinträge
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Zusammenfassung von {student.first_name}'s Strafeeinträgen
                </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Gesamt</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {summaries.penalty_summary.sum_penalties}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Offen</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {summaries.penalty_summary.sum_penalties_open}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Abgeleistet</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {summaries.penalty_summary.sum_penalties_compensated}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Verjährt</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {summaries.penalty_summary.sum_penalties_time_barred}
                        </dd>
                    </div>
                    {!isExportable && <div className="py-4 sm:py-5 sm:gap-4 sm:px-6">
                        {checkPermission(permissions, "student_logbook.add_penalty") && (
                            <PrimaryButton
                                link={`/logbook/penalties/new/${student.id}`}
                                label="Strafe eintragen"
                            />
                        )}
                    </div>}
                </dl>
            </div>
        </div>
    );
}

function StudentLogbookEntries({logbookEntries, student, permissions, isExportable}) {

    const {data: categories} = useQuery(['logbook_entry_categories'], getLogbookEntryCategories, {refetchInterval: 1000 * 60 * 60 * 24})
    const {categoryId} = useParams();
    const {renderUserImage} = useUserInfo();

    const selectedCategory = categories?.find(cat => cat.id == categoryId)

    return (
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Logbucheinträge {selectedCategory?.name}
                    </h2>
                    {selectedCategory && <SecondaryButton classNameIn="" link={`/logbook/students/${student.id}`} label="Alle Kategorien anzeigen"/>}
                </div>

                <div className="px-4 py-6 sm:px-6">
                    <ul role="list" className="space-y-8">
                        {logbookEntries &&
                            logbookEntries.map((lbe) => (
                                <li key={lbe.id}>
                                    <div className="flex space-x-3">
                                        <div className="flex-shrink-0">
                                            {renderUserImage(lbe.created_by)}
                                        </div>
                                        <div>
                                            <div className="text-sm">
                                                <Link
                                                    to={`/logbook/entries/${lbe.id}`}
                                                    className="font-medium text-gray-900"
                                                >
                                                    {lbe.title}
                                                </Link>
                                            </div>
                                            <div className="mt-1 text-sm text-gray-700">
                                                <p className="whitespace-pre-wrap">{lbe.entry}</p>
                                            </div>

                                            {lbe.number_of_penalties > 1 && (
                                                <div className="mt-2 text-sm space-x-2 text-gray-500 font-medium">
                                                    {lbe.number_of_penalties} Strafeinträge
                                                </div>
                                            )}
                                            {lbe.number_of_penalties == 1 && (
                                                <div className="mt-2 text-sm space-x-2 text-gray-500 font-medium">
                                                    {lbe.number_of_penalties} Strafeintrag
                                                </div>
                                            )}

                                            <div className="mt-2 text-sm space-x-2">
                        <span className="text-gray-500 font-medium">
                          {moment(lbe.creation_date).format("DD.MM.YYYY")}
                        </span>{" "}
                                                <span className="text-gray-500 font-medium">
                          &middot;
                        </span>{" "}
                                                <span className="text-gray-500 font-medium">
                          von <UserName userId={lbe.created_by}/>
                        </span>{" "}
                                                <span className="text-gray-500 font-medium">
                          &middot;
                        </span>{" "}
                                                <span className="text-gray-500 font-medium">
                          Sichtbar für {lbe.get_visibility_display}
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            {!isExportable && <div className="bg-gray-50 px-4 py-6 sm:px-6">
                <div className="flex space-x-3">
                    <div className="min-w-0 flex-1">
                        <form action="src/components/modules/student_logbook/StudentDetail#">
                            <div className="mt-3 flex items-center justify-between">
                                {checkPermission(
                                    permissions,
                                    "student_logbook.add_logbookentry"
                                ) && (
                                    <PrimaryButton
                                        link={`/logbook/entries/new/${student.id}`}
                                        label="Neuen Eintrag hinzufügen"
                                    />
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
        </div>
    );
}

function PenaltyEntries({penaltyEntries, student, permissions}) {
    function getRowLink(row) {
        return `/logbook/entries/${row.original.id}/`;
    }

    const columns = [
        // {
        //   accessorKey: "id",
        //   id: "id",
        //   header: "ID",
        //   enableColumnFilter: false,
        // },
        {
            accessorKey: "creation_date",
            id: "creation_date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "kind",
            id: "kind",
            header: "Art",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "descr",
            id: "descr",
            header: "Titel",
            enableColumnFilter: false,
        },
        {
            accessorKey: "num",
            id: "num",
            header: "Strafeinträge",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "is_time_barred",
            id: "is_time_barred",
            header: "Verjährt",
            columnType: "yesno",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "num_count",
            id: "num_count",
            header: "Zählt",
            columnType: "number",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "calculated", // TODO do the math here
            id: "calculated",
            header: "Gesamt",
            enableColumnFilter: false,
            classNames: "text-gray-500 calc",
            cell: ({row, value}) => {
                var sum = 0;
                for (let i = 0; i <= row.index; i++) {
                    var curEntry = penaltyEntries[i];
                    if (curEntry.num_count) sum = sum + parseInt(curEntry.num_count)
                }
                return sum
            }
        }
    ];

    return (
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Strafeinträge
                    </h2>
                </div>
                <div className="">
                    {penaltyEntries && (
                        <ListViewTable
                            data={penaltyEntries}
                            columns={columns}
                            disableGlobalSearch={true}
                            getRowLink={getRowLink}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function Penalties({penalties, student, permissions, isExportable}) {
    // function getRowLink(row) {
    //     return `/logbook/penalties/${row.original.id}/`;
    // }

    const columns = [
        {
            accessorKey: "penalty.name",
            id: "penalty",
            header: "Strafe",
            enableColumnFilter: false,
        },
        {
            accessorKey: "date_from",
            id: "date_from",
            header: "Abzuleisten am",
            columnType: "date",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "date_to",
            id: "date_to",
            header: "Bis",
            columnType: "date",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "compensated",
            id: "compensated",
            header: "Abgeleistet",
            enableColumnFilter: false,
            columnType: "yesno",
        },
        {
            accessorKey: "remarks",
            id: "remarks",
            header: "Anmerkungen",
            enableColumnFilter: false,
        },
        {
            accessorKey: "created_by",
            id: "created_by",
            header: "Erstellt von",
            cell: (info) => <UserName link={true} userId={info.getValue()}/>,
            enableColumnFilter: false,
        },
        {
            accessorKey: "creation_date",
            id: "creation_date",
            header: "Erstellt am",
            columnType: "date",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
    ];

    const editColumn = {
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <TableLink
                    label="Bearbeiten"
                    to={`/logbook/penalties/${info.getValue()}/edit`}
                />
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
            disableRowLink: true
        }

    let tableColumns = columns
    if (checkPermission(permissions, "student_logbook.change_penalty")) {
        tableColumns.push(editColumn)
    }

    return (
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Strafen
                    </h2>
                </div>
                <div className="">
                    {penalties && (
                        <ListViewTable
                            data={penalties}
                            columns={tableColumns}
                            disableGlobalSearch={true}
                            getRowLink={null}
                        />
                    )}
                </div>
            </div>

            {!isExportable && <div className="bg-gray-50 px-4 py-6 sm:px-6">
                <div className="flex space-x-3">
                    <div className="min-w-0 flex-1">
                        {checkPermission(permissions, "student_logbook.add_penalty") && (
                            <PrimaryButton
                                link={`/logbook/penalties/new/${student.id}`}
                                label="Strafe eintragen"
                            />
                        )}
                    </div>
                </div>
            </div>}
        </div>
    );
}
