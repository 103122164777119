import {SimplePage} from "../../elements/Pages";
import React from "react";
import {useParams} from "react-router-dom";
import {EditButton, ListButton} from "../../elements/Buttons";
import {getHomeworkDataForStudierzeitAttendance} from "../../../api/studierzeit";
import {formatDate} from "../../../utils/formatters";
import {StudentExams, StudentHomeworkDetail} from "../class_register/StudentDetail";
import {useQuery} from "@tanstack/react-query";


export default function AttendanceDetailWithHomework() {

    const {id} = useParams()
    const {data} = useQuery(['studierzeit_attendance_homework', id], () => getHomeworkDataForStudierzeitAttendance(id), {refetchInterval: 1000 * 60 * 60 * 1})

    if (!data) return <></>
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medi`um text-gray-900">
                            Hausaufgaben für Studierzeit {data.attendance.studierzeit_type.name} {data.attendance.room?.name} {formatDate(data.attendance.date)}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <ListButton link={`/studierzeit/attendance/${id}`}
                                    label="Liste ansehen"/>

                        <EditButton link={`/studierzeit/attendance/${id}/edit`}
                                       label="Bearbeiten"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        {data && data.student_homework.map((sh, index) => (
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mb-8">
                                <div className="">
                                    <h2 className="text-md font-medium text-gray-900">
                                        {sh.student.full_name}
                                    </h2>
                                    <div className="-mt-4">
                                        <StudentHomeworkDetail key={index} studentId={sh.student.id}
                                                               previousOrCurrent="attendance"
                                                               date={data.attendance.date}
                                        />
                                    </div>
                                    <h3 id="notes-title" className="mt-4 text-sm font-medium text-gray-900">
                                        Anstehende Klausuren
                                    </h3>
                                    <div className="">
                                        <StudentExams key={index} data={sh}
                                        />
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}