import React, { useContext } from "react";
import { useEffect, useState} from "react";

const ShopCartContext = React.createContext();

export function useShopCart() {
    return useContext(ShopCartContext);
}

export function ShopCartProvider({ children }) {

    const [cartList, setCartList] = useState([]);
    const [totalPrice, setTotalPrice] = useState(-1);

    const addProductToCart = (product) => {
        setCartList([...cartList, product])
        setTotalPrice(totalPrice + +product.product.price)
    };

    const removeProductFromCart = (product) => {
        setCartList(cartList.filter(item => item !== product))
        setTotalPrice(totalPrice - (+product.product.price))
    };

    const removeAllProductsFromCart = () => {
        setCartList([])
        setTotalPrice(0)
    }

    const calcTotalPrice = (cartItems) => {
        let price = 0
        let parsedCartList = []
        if (cartItems) {
            parsedCartList = JSON.parse(cartItems)
            parsedCartList.forEach(item => {
                price += +item.product.price
            });
        }
        setTotalPrice(price)
        setCartList(parsedCartList)
    };

    useEffect(() => {
        const cartItems = localStorage.getItem("cartList")
        calcTotalPrice(cartItems)
    }, [])

    useEffect(() => {
        totalPrice !== -1 && localStorage.setItem("cartList", JSON.stringify(cartList))
    }, [cartList])

    const value = {
        cartList,
        totalPrice,
        addProductToCart,
        removeProductFromCart,
        removeAllProductsFromCart,
    };

    return (
        <ShopCartContext.Provider value={value}>
            {children}
        </ShopCartContext.Provider>
    );
}
