import {NarrowPage, Page, SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";
import {Link, useParams} from "react-router-dom";
import Form, {baseHandleChange, baseHandleChangeNested, baseHandleListChange} from "../../elements/forms/Form";
import {toast} from "react-toastify";
import {
    getCertificateRemarks,
    getCertificateRemarksAndFinalConductGrades, getMovingUpStatus, updateCertificateRemarksAndFinalConductGrades,
    updateMultipleCertificateRemarks
} from "../../../api/certificates";
import {Input, Select, TextArea} from "../../elements/forms/Inputs";
import {EditButton, PrimaryButton} from "../../elements/Buttons";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {useQuery} from "@tanstack/react-query";
import {getStudentInfo} from "../../../api/school";
import {getConductGradeValues} from "../../../api/assessments";
import {formatTimeFromDateTime} from "../../../utils/formatters";


export default function EditCertificateRemarksAndConductGrades() {
    const {assessmentPeriodId, studentId} = useParams()

    const schema = {}
    const [errors, setErrors] = useState([]) // validation errors

    const [data, setData] = useState()

    const {data: conductGradeValues} = useQuery(["conduct_grade_values"], getConductGradeValues, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: movingUpStatus} = useQuery(["moving_up_status"], () => getMovingUpStatus(assessmentPeriodId), {refetchInterval: 1000 * 60 * 60 * 24});

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        const newData = await getCertificateRemarksAndFinalConductGrades(assessmentPeriodId, studentId)
        setData(newData)
    }

    function setFinalConductGrades(newCrs) {
        setData({...data, certificate_remarks_data: {...data.certificate_remarks_data, final_conduct_grades: newCrs}})
    }

    function handleListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.certificate_remarks_data.final_conduct_grades, setFinalConductGrades, errors, setErrors, schema)
    }

    function handleChange(evt, rowId) {
        baseHandleChangeNested(evt, data, setData, errors, setErrors, schema)
    }

    async function handleSave() {
        try {
            console.log(data.certificate_remarks_data)
            await updateCertificateRemarksAndFinalConductGrades(assessmentPeriodId, studentId, data.certificate_remarks_data)
            loadData()
            toast.success("Kopfnoten/Zeugnissätze gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    function copyTextItem(name) {
        let crData = {...data.certificate_remarks_data}
        crData.remarks = `${crData.remarks ? crData.remarks : ""} ${name}`.trim()
        setData({...data, certificate_remarks_data: crData})
    }

    const title = "Kopfnoten & Zeugnisbemerkung bearbeiten"
    const successMessage = "Kopfnoten & Zeugnisbemerkung aktualisiert"


    if (!data) return <></>
    return (
        <Page title={title}>

            <div
                className=" max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <Form id="updateCertificateRemarksAndFinalConductGrades"
                          onSave={handleSave}
                          onDelete={null}
                          data={data} setData={setData}
                          errors={errors} setErrors={setErrors}
                          successMessage={successMessage}
                          schema={schema}>


                        <FormSectionContent>

                            <h3 className="text-lg leading-6 font-medium text-gray-900 sm:col-span-6">
                                <Link className="text-imsblue-600"
                                      to={`/school/students/${data?.certificate_remarks_data.student.id}`}>{data?.certificate_remarks_data.student.full_name}</Link>, {data?.assessment_period?.name}
                            </h3>

                            <TextArea
                                path="certificate_remarks_data.remarks"
                                label="Zeugnisbemerkung"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                                rows={4}
                            />


                            {data.certificate_remarks_data.final_conduct_grades.map((f, index) => {

                                const cga = data.certificate_remarks_data.conduct_grade_averages.find(c => c.conduct_grade_definition_id === f.conduct_grade_definition.id)

                                return (
                                    <div key={index}
                                         className="px-2 py-2 align-middle shadow ring-1 ring-black ring-opacity-5 md:rounded-lg sm:col-span-6 ">

                                        <h4 className="ml-4 text-md mb-2 font-medium">{f.conduct_grade_definition.name}</h4>

                                        <table className="min-w-full table-fixed ">
                                            <thead>
                                            <tr className="bg-gray-50">
                                                <th className="pl-5 py-3.5 text-left text-xs">Fach</th>
                                                <th className="py-3.5 text-left text-xs">Lehrer</th>
                                                <th className="py-3.5 text-left text-xs">Note</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {cga?.course_conduct_grades.map((ca, caIndex) => (
                                                <tr key={caIndex}
                                                    className={(caIndex % 2 === 0 ? undefined : "bg-gray-50")}>
                                                    <td className="py-4 pl-4 text-sm">{ca.subject} ({ca.course_number})</td>
                                                    <td className="py-4 text-sm">{ca.teacher}</td>
                                                    <td className="py-4 text-sm">{ca.course_avg}</td>
                                                </tr>
                                            ))}

                                            <tr key="avg">
                                                <td colSpan="2" className="py-4 pl-4 text-sm">Gesamtdurchschnitt:</td>
                                                <td className="py-4 text-sm">{cga.final_conduct_grade_avg}</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <div className="flex justify-between items-center ">

                                            <div className="font-medium mr-6 ml-4 text-sm">Erteilte Note:</div>
                                            <Select
                                                path="mark"
                                                label="Erteilte Note"
                                                className=" hide-label w-24"
                                                options={conductGradeValues}
                                                valueAttr="id"
                                                labelAttr="mark"
                                                onChange={handleListChange}
                                                data={data.certificate_remarks_data.final_conduct_grades}
                                                isClearable={false}
                                                valueOnly={false}
                                                rowId={f.id}
                                            />

                                        </div>


                                    </div>)
                            })}


                        </FormSectionContent>

                    </Form>
                </div>

                <div className="space-y-6 lg:col-span-1">

                    <section aria-labelledby="applicant-information-title" className="bg-gray-100">

                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Vorgeschlagene Versetzungsbemerkungen kopieren:
                            </h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">

                            {movingUpStatus &&
                                <ul className="px-4 py-5">
                                    {movingUpStatus.map((a, index) => (
                                        <li
                                            onClick={() => copyTextItem(a.name)}
                                            className="cursor-pointer py-2" key={index}>
                                            {a.name}
                                        </li>
                                    ))}
                                </ul>}

                        </div>

                    </section>
                </div>

            </div>

        </Page>
    );
}
