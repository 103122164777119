import {useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {AddButton, DownloadButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {DeleteButton} from "../../elements/DeleteButton";
import {
    copyCourseTopics,
    createOrUpdateMultipleCourseTopics, deleteCourseTopic,
    getAllCoursesWithSchoolYear,
    getCourse,
    getCourseTopics,
    getCourseTopicsPDF
} from "../../../api/courses";
import {Checkbox, Select, TextArea} from "../../elements/forms/Inputs";
import {baseHandleChange, baseHandleListChange} from "../../elements/forms/Form";
import {toast} from "react-toastify";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {getTimePeriods} from "../../../api/calendar";
import {formatDate} from "../../../utils/formatters";

export default function CourseTopics() {

    const {courseId} = useParams();
    const [courseTopics, setCourseTopics] = useState([])
    const [errors, setErrors] = useState([]) // validation errors
    const [courseTopicsErrors, setCourseTopicsErrors] = useState([]) // validation errors
    const [editingMode, setEditingMode] = useState(false)
    const [newRowId, setNewRowId] = useState(1)

    const [copyTopicsData, setCopyTopicsData] = useState({
        from_course_id: null,
        to_course_id: courseId
    })

    const {data: course} = useQuery(
        ["course_detail", courseId],
        () => getCourse(courseId)
    )
    const {data: courses} = useQuery(["courses_with_school_year"], getAllCoursesWithSchoolYear, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: timePeriods} = useQuery(['time_periods'], getTimePeriods, {refetchInterval: 1000 * 60 * 60 * 24})

    const schema = {}

    useEffect(() => {
        loadData()
    }, [courseId])

    async function loadData() {
        const newData = await getCourseTopics(courseId)
        setCourseTopics(newData)
    }

    function addRow() {

        const newRow = {
            id: `N${newRowId}`,
            fulfilled: null,
            explanation: null,
            curriculum: null,
            time_period: {
                id: null
            },
            course: {
                id: courseId
            }
        }
        setNewRowId(newRowId + 1)
        setCourseTopics([...courseTopics, newRow])
        setEditingMode(true)
    }

    async function handleSave() {
        try {
            await createOrUpdateMultipleCourseTopics(courseTopics)
            loadData()
            toast.success("Themenverteilung gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleCopyFromCourse() {
        const newData = await copyCourseTopics(copyTopicsData)
        setCourseTopics(newData)
        toast.success("Themeverteilung kopiert.")
    }

    function handleChange(evt) {
        baseHandleChange(evt, copyTopicsData, setCopyTopicsData, errors, setErrors, {})
    }

    function handleListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, courseTopics, setCourseTopics, courseTopicsErrors, setCourseTopicsErrors, schema)
    }

    async function handleDelete(courseTopicId) {
        if (courseTopicId.toString().startsWith("N")) {
            const newData = courseTopics.filter(row => row.id !== courseTopicId)
            setCourseTopics(newData)
        } else {
            try {
                await deleteCourseTopic(courseTopicId)
                loadData()
                toast.success("Thema gelöscht")
            } catch (e) {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
    }

    if (!course) return <></>
    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {course.course_number}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {course.school_subject.name}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <DownloadButton
                            apiFunction={() => getCourseTopicsPDF(courseId)}
                            fileName={`Themenverteilung ${course.course_number}.pdf`}
                            label="PDF herunterladen" />
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        {course.can_edit &&

                            <div className="flex justify-between">
                                <div className="my-4 ml-4">
                                    <AddButton onClick={addRow} label="Hinzufügen"/>
                                </div>

                                {editingMode &&

                                    <div className="flex justify-end gap-2">
                                        <SecondaryButton
                                            onClick={() => setEditingMode(false)}
                                            classNameIn="my-4" label="Abbrechen"/>
                                        <PrimaryButton
                                            classNameIn="my-4"
                                            onClick={handleSave} label="Speichern"/>

                                    </div>
                                }

                                {!editingMode && <PrimaryButton
                                    classNameIn="my-4"
                                    onClick={() => setEditingMode(true)} label="Bearbeiten"/>}
                            </div>

                        }

                        {courseTopics &&
                            <section aria-labelledby="applicant-information-title">

                                {courseTopics.map((courseTopic, index) => (
                                    <div key={index} className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">

                                        <div className="border-t border-gray-200 px-4 py-5">

                                            {course.can_edit && editingMode &&
                                                <FormSectionContent>

                                                    <Select
                                                        path="time_period"
                                                        label="Zeitraum"
                                                        className="sm:col-span-6"
                                                        options={timePeriods}
                                                        valueAttr="id"
                                                        labelAttr="label"
                                                        onChange={handleListChange}
                                                        data={courseTopics}
                                                        isClearable={false}
                                                        valueOnly={false}
                                                        rowId={courseTopic.id}
                                                    />

                                                    <TextArea
                                                        path="curriculum"
                                                        label="Lehrstoff & Inhalte"
                                                        className="sm:col-span-6"
                                                        onChange={handleListChange}
                                                        errors={courseTopics}
                                                        data={courseTopics}
                                                        rowId={courseTopic.id}
                                                        rows={20}
                                                    />

                                                    <Checkbox
                                                        path="fulfilled"
                                                        label="Erfüllt"
                                                        className="sm:col-span-6"
                                                        onChange={handleListChange}
                                                        errors={courseTopics}
                                                        data={courseTopics}
                                                        rowId={courseTopic.id}
                                                    />

                                                    <TextArea
                                                        path="explanation"
                                                        label="Begründung"
                                                        className="sm:col-span-6"
                                                        onChange={handleListChange}
                                                        errors={courseTopics}
                                                        data={courseTopics}
                                                        rowId={courseTopic.id}
                                                        rows={10}
                                                    />

                                                    <DeleteButton onDelete={() => handleDelete(courseTopic.id)}/>

                                                </FormSectionContent>}

                                            {!editingMode &&
                                                <dl className="sm:divide-y sm:divide-gray-200">
                                                    <div
                                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Zeitraum</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                            <h3 className="text-md leading-6 font-medium text-gray-900">{courseTopic.time_period.label}</h3>
                                                        </dd>
                                                    </div>
                                                    <div
                                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Lehrstoff &
                                                            Inhalte
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">{courseTopic.curriculum}
                                                        </dd>
                                                    </div>
                                                    <div
                                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Themenverteilungsplan
                                                            erfüllt
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                            {courseTopic.fulfilled === true ? "Ja" : "Nein"}
                                                        </dd>
                                                    </div>
                                                    <div
                                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Begründung</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">{courseTopic.explanation}
                                                        </dd>
                                                    </div>
                                                    <div
                                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Erstellt am
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatDate(courseTopic.creation_date)}
                                                        </dd>
                                                    </div>
                                                </dl>}

                                        </div>
                                    </div>))}


                            </section>}


                        {course.can_edit &&

                            <div className="flex justify-between">
                                <div className="my-4 ml-4">
                                    <AddButton onClick={addRow} label="Hinzufügen"/>
                                </div>

                                {editingMode &&

                                    <div className="flex justify-end gap-2">
                                        <SecondaryButton
                                            onClick={() => setEditingMode(false)}
                                            classNameIn="my-4" label="Abbrechen"/>
                                        <PrimaryButton
                                            classNameIn="my-4"
                                            onClick={handleSave} label="Speichern"/>

                                    </div>
                                }

                                {!editingMode && <PrimaryButton
                                    classNameIn="my-4"
                                    onClick={() => setEditingMode(true)} label="Bearbeiten"/>}
                            </div>

                        }

                    </div>

                    <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1 mt-0">

                        {courses &&
                            <section className="" aria-labelledby="applicant-information-title">

                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Themenverteilungsplan von einem anderen Kurs kopieren
                                        </h3>
                                        <p className="text-sm font-medium text-gray-900 py-2">
                                            Der gesamte Themenverteilungsplan für den ausgewählten Kurs wird
                                            übernommen. Bestehende Einträge im Kurs {course.course_number} bleiben
                                            unberührt.
                                        </p>
                                    </div>

                                    <div className="border-t border-gray-200 px-4 py-5">
                                        <Select
                                            path="from_course_id"
                                            label="Kurs"
                                            className="sm:col-span-6 hide-label"
                                            options={courses}
                                            valueAttr="id"
                                            labelAttr="full_name"
                                            onChange={handleChange}
                                            data={copyTopicsData}
                                            isClearable={false}
                                            valueOnly={true}
                                        />

                                        <PrimaryButton
                                            classNameIn="mt-2 w-full"
                                            label="Themenverteilungsplan kopieren"
                                            onClick={handleCopyFromCourse} />
                                    </div>
                                </div>

                            </section>}

                    </section>


                </div>

            </main>
        </>
    )
}
