import {SimplePage} from "../../elements/Pages";
import React from 'react';

import {useQuery} from '@tanstack/react-query'
import ListViewTable from "../../elements/tables/ListViewTable";
import {getSchoolClassesFull} from "../../../api/school";


const columns = [
    {
        accessorKey: 'group',
        id: 'group',
        header: 'Klasse',
        enableColumnFilter: false,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: "students",
        id: "students",
        header: "SchülerInnen",
        enableColumnFilter: false,
        columnType: "m2mLinked",
        separator: "<br/>",
        getItemLink: (item) => {
            return `/school/students/${item.id}/`
        },
        labelAttr: "full_name",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "class_teacher",
        id: "class_teacher",
        header: "KlassenlehrerIn",
        columnType: "staff",
        enableColumnFilter: false,
    },
    {
        accessorKey: "class_representative",
        cell: ({row, value}) => row.original.class_representative ? `${row.original.class_representative?.full_name}` : "",
        id: "class_representative",
        header: "KlassensprecherIn",
        // columnType: "student",
        enableColumnFilter: false,
    }
]


export default function SchoolClassList() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(['school_classes_full'], getSchoolClassesFull, {refetchInterval: 1000 * 60 * 60 * 24})

    function getRowLink(row) {
        return `/school/school-classes/${row.original.id}/`
    }

    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Klassen</h3>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && <ListViewTable data={data}
                                                                 columns={columns}
                                                                 isLoading={isLoading}
                                                                 disableGlobalSearch={false}
                                                                 getRowLink={getRowLink}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
